<div class="global-progress-overlay" *ngIf="(progress$ | async)?.isInProgress">
  <div class="floating-progress" [class.minimized]="(progress$ | async)?.minimized">
    <div class="progress-header">
      <span>Registering Users</span>
      <div class="actions">
        <button class="minimize-btn" (click)="toggleMinimize()">
          <i class="fas" [class.fa-minus]="!(progress$ | async)?.minimized" [class.fa-expand]="(progress$ | async)?.minimized"></i>
        </button>
      </div>
    </div>
    <div class="progress-content" *ngIf="!(progress$ | async)?.minimized">
      <p>Progress: {{(progress$ | async)?.processed}}/{{(progress$ | async)?.total}} users registered</p>
      <div class="progress-bar">
        <div class="progress" [style.width.%]="((progress$ | async)?.processed / (progress$ | async)?.total) * 100"></div>
      </div>
      <p class="progress-note">You can continue using the application while users are being registered</p>
    </div>
  </div>
</div> 