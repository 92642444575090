import { Component, OnInit, OnDestroy } from '@angular/core';
import { BulkRegisterService } from 'src/app/services/bulk-register.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-registration-progress',
  templateUrl: './registration-progress.component.html',
  styleUrls: ['./registration-progress.component.scss']
})
export class RegistrationProgressComponent implements OnInit, OnDestroy {
  isVisible = false;
  total = 0;
  processed = 0;
  minimized = false;
  private subscription: Subscription;
  progress$ = this.bulkRegisterService.progress$;

  constructor(private bulkRegisterService: BulkRegisterService) {}

  ngOnInit() {
    this.subscription = this.bulkRegisterService.progress$.subscribe(progress => {
      this.isVisible = progress.isInProgress;
      this.total = progress.total;
      this.processed = progress.processed;
      this.minimized = progress.minimized;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get progressPercentage(): number {
    return (this.processed / this.total) * 100;
  }

  toggleMinimize() {
    this.bulkRegisterService.toggleMinimize();
  }
} 