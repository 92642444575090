<section class="flex register-profesori container">
   <main>
      <form [formGroup]="professorRegister">
         <h5>{{ 'general.full_name' | translate }}</h5>
         <input formControlName="professorName" type="text" />
         <div
            *ngIf="
               professorRegister.controls.professorName.touched &&
               professorRegister.controls.professorName.invalid
            "
            class="invalid"
         >
            {{ 'general.full_name' | translate }} {{ 'aria-labels.is_required' | translate }}
         </div>
         <div
            *ngIf="
               professorRegister.controls.professorName.errors?.minlength
            "
            class="invalid"
         >
            {{ 'general.full_name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}.
         </div>
         <h5>{{ 'app.institution' | translate }}</h5>
         <select
            formControlName="professorFaculty"
            (change)="facultyChanged()"
         >
            <option selected disabled value="">{{ 'general.select_institution' | translate }}</option>
            <ng-container *ngFor="let faculty of this.faculties">
               <option value="{{ faculty.id }}">
                  {{ faculty.name }}
               </option>
            </ng-container>
         </select>
         <div
            *ngIf="
               professorRegister.controls.professorFaculty.touched &&
               professorRegister.controls.professorFaculty.invalid
            "
            class="invalid"
         >
         {{ 'general.educator' | translate }} {{ 'app.institution' | translate }} {{ 'aria-labels.is_required' | translate }}*
         </div>
         <h5>{{ 'general.department' | translate }}</h5>
         <select
            disabled
            *ngIf="
               professorRegister.controls.professorFaculty.value == '' ||
               professorRegister.controls.professorFaculty == undefined
            "
            name=""
            id=""
         >
            <option value="">{{ 'general.select_institution_first' | translate }}</option>
         </select>
         <select
            *ngIf="
               professorRegister.controls.professorFaculty.value !== '' &&
               professorRegister.controls.professorFaculty !== undefined
            "
            formControlName="professorDepartment"
            (change)="departmentChanged()"
         >
            <option selected disabled value="">{{ 'general.select_department' | translate }}</option>
            <ng-container *ngFor="let department of this.departments">
               <option value="{{ department.id }}">
                  {{ department.name }}
               </option>
            </ng-container>
         </select>
         <div
            *ngIf="
               professorRegister.controls.professorDepartment.touched &&
               professorRegister.controls.professorDepartment.invalid
            "
            class="invalid"
         >
         {{ 'general.educator' | translate }} {{ 'general.department' | translate }} {{ 'aria-labels.is_required' | translate }}*
         </div>
         <h5 class="emailAddress">{{ 'general.email' | translate }}</h5>
         <input
            formControlName="professorEmail"
            type="email"
         />
         <!-- <div *ngIf="this.emailExists" class="invalid">
            Email already exists, please try another one.*
         </div> -->
         <div
            *ngIf="
               professorRegister.controls.professorEmail.touched &&
               professorRegister.controls.professorEmail.invalid
            "
            class="invalid"
         >
         {{ 'general.educator' | translate }} {{ 'login.email_required' | translate }}*
         </div>
   
         <div
            *ngIf="professorRegister.controls.professorEmail.errors?.email"
            class="invalid"
         >
         {{ 'app.educator_email_is_not_valid' | translate }}*
         </div>
         <h5>{{ 'general.unique_identifier' | translate }}</h5>
         <input
            formControlName="uniqueIdentifier"
            type="text"
            placeholder="{{ 'general.unique_identifier_placeholder' | translate }}"
         />
         <div class="center">
            <button
               [disabled]="professorRegister.invalid || this.emailExists"
               [ngClass]="{
                  disable: professorRegister.invalid || this.emailExists
               }"
               class="submit"
               type="submit"
               (click)="submit()"
            >
               {{ 'app.confirm' | translate }}
            </button>
         </div>
      </form>
   </main>
</section>