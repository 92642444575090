// *** Angular
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';

// *** Packages
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

// *** Actions
import * as professorActions from '../state/professor/action/professor.actions';

// *** Selectors
import { getCurrentUser } from 'src/app/authModule/state/authentication.selectors';
import { getInstitutionProfessorsStateDetails } from '../state/professor/selector/professor.selectors';

// *** Services
import { CourseService } from 'src/app/services/course.service';
import { DepartmentService } from 'src/app/services/department.service';
import { FacultyService } from 'src/app/services/faculty.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelService } from 'src/app/services/excel.service';
import { UploadfileService } from 'src/app/individual-components/individual-uploads/upload-file/Service/uploadfile.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-educator-form',
  templateUrl: './register-educator-form.component.html',
  styleUrls: ['./register-educator-form.component.scss']
})
export class RegisterEducatorFormComponent implements OnInit {
/**
    * Variable used to know if user is using excel form or manual form
    */
excel = false;
/**
 * Variable used to store faculties of institution
 */
faculties;
/**
 * Variable used to store departments of selected faculty
 */
departments = [];

/**
 * Variable used to store selected department courses
 */
courses = [];

/**
 * Form group used to get user input
 */
professorRegister: FormGroup;

/**
 * Variable stores configuration of ngx-select-dropdown
 */
config;
/**
 * Variables used to store information of user retrived from store
 */
user$;
user;
/**
 * Variable used to store value of words left for institution
 */
institutionWordsLeft;
/**
 * Variable used to store a time for function to trigger.(x time after key is up call y function.)
 */
timer;
/**
 * Variable used to store a boolean value for validating email.
 */
emailExists: boolean = false;
getInstitutionProfessorsStateDetails$: any;

/**
 * Variable used to store selected Faculty for bulk upload method
 */
selectedFaculty: string;
/**
 * Variable used to store selected Department for bulk upload method
 */
selectedDepartment: string;
fileToUpload: File;
showErrors: boolean = false;
errorFromExcel = [];
currentUser$: any;
currentUserDetailsSubscriber$: any;

/**
 * Variable to store Excel data for preview
 */
excelData: any[] = [];
previewData: any[] = [];
showPreview: boolean = false;
fixedErrors: Set<number> = new Set();
fileUploaded = false;
currentPage: number = 1;
pageSize: number = 20;
pageSizeOptions: number[] = [10, 20, 30, 50, 100];
totalPages: number = Math.ceil(this.previewData.length / this.pageSize);

/**
 * Add new property for filtering
 */
showOnlyErrors: boolean = false;

/**
 * Map to store course codes and their corresponding course objects
 */
courseCodeMap: Map<string, any> = new Map();

/**
 * Variable to store the name of the uploaded file
 */
uploadedFileName: string = '';

/**
 * Variables for upload progress tracking
 */
isUploading: boolean = false;
uploadProgress: number = 0;
chunkSize: number = 2; // Number of records to process at once
processedCount: number = 0;

/**
 *
 * @param fb
 * @param facultyService
 * @param departmentService
 * @param courseService
 * @param store
 * @param userService
 * @param spinnerService
 * @param dialogRef
 * @param data
 * @param excelService
 */
constructor(
   private fb: FormBuilder,
   private facultyService: FacultyService,
   private departmentService: DepartmentService,
   private courseService: CourseService,
   private store: Store,
   private userService: UserService,
   private spinnerService: NgxSpinnerService,
   @Optional() public dialogRef: MatDialogRef<any>,
   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
   private excelService: ExcelService,
   private uploadfileService: UploadfileService,
   private toastrService: ToastrService,
   public translate: TranslateService,
   private cd: ChangeDetectorRef
) {}
ngOnDestroy(): void {
   this.currentUserDetailsSubscriber$.unsubscribe();
   this.getInstitutionProfessorsStateDetails$.unsubscribe();
}

ngOnInit(): void {
   this.currentUser$ = this.store.select(getCurrentUser);
   this.currentUserDetailsSubscriber$ = this.store
      .select(getCurrentUser)
      .subscribe((data: User) => {
          this.user = data;
          this.institutionWordsLeft = this.user.Institution.wordsLeft;
      });
      this.translate.get(['new_entries.select', 'general.search', 'new_entries.no_results_found']).subscribe(translations => {
        this.config = {
           displayKey: 'title',
           search: true,
           height: 'auto',
           placeholder: translations['new_entries.select'],
           moreText: 'more',
           noResultsFound: translations['new_entries.no_results_found'],
           searchPlaceholder: translations['general.search'],
           searchOnKey: 'title',
           clearOnSelection: false,
           inputDirection: 'ltr',
           enableSelectAll: true,
           selectAllLabel: 'Select all',
           multiple: true
        };
      })
   this.facultyService
      .getFacultiesOfInstitution(0)
      .pipe(first())
      .subscribe(
         (data) => {
            this.faculties = data.faculties;
         },
         (error) => {
            console.log(error);
         }
      );
   this.professorRegister = this.fb.group({
      professorName: ['', [Validators.required, Validators.minLength(3)]],
      professorFaculty: ['', Validators.required],
      professorDepartment: ['', Validators.required],
      professorEmail: ['', [Validators.required, Validators.email]],
      professorWordsToUse: [0],
      professorCourse: [''],
      uniqueIdentifier: ['']
   });

   this.getInstitutionProfessorsStateDetails$ = this.store
      .select(getInstitutionProfessorsStateDetails)
      .subscribe((data) => {
         if (data.professorRegistered !== null) {
            if (this.dialogRef) {
               this.dialogRef.close();
            } else {
               this.professorRegister.reset();
               this.store.dispatch(
                  professorActions.setProfessorRegisterToNull()
               );
            }
         }
      });
}
/**
 * Method is used to calculate number of words that will be left while administrator allocates words to be used to professor.
 */
calculate() {
   this.form.professorWordsToUse.setValue(
      Math.floor(this.professorRegister.value.professorWordsToUse)
   );
   this.institutionWordsLeft = this.user.Institution.wordsLeft;
   if (this.professorRegister.value.professorWordsToUse < 0) {
      this.form.professorWordsToUse.setValue(0);
   } else {
      if (
         this.professorRegister.value.professorWordsToUse >
         this.user.Institution.wordsLeft
      ) {
         this.institutionWordsLeft = 0;
         this.form.professorWordsToUse.setValue(
            this.user.Institution.wordsLeft
         );
      } else {
         this.institutionWordsLeft =
            this.institutionWordsLeft -
            this.professorRegister.value.professorWordsToUse;
      }
   }
}

/**
 * Method is used to toggle between excel upload form and manual register form.
 */
toggleForm(type: 'manual' | 'bulk') {
   this.excel = type === 'bulk';
   this.resetForm(); // Clear any existing data when switching modes
   this.fileUploaded = false;
}
/**
 * Method is used to create a professorRegister.
 */
async submit() {
   const result = await Swal.fire({
      title: 'You are about to add educators, please confirm your action by clicking "Confirm".',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#b5adad',
      confirmButtonText: this.translate.instant('app.confirm'),
      cancelButtonText: this.translate.instant('report.cancel'),
   });
   
   if (result.isConfirmed) {
      const payload = {
         professorName: this.professorRegister.value.professorName,
         professorFaculty: this.professorRegister.value.professorFaculty,
         professorDepartment: this.professorRegister.value.professorDepartment,
         professorSubjects: this.professorRegister.value.professorCourse,
         professorEmail: this.professorRegister.value.professorEmail,
         professorWords: this.professorRegister.value.professorWordsToUse,
         uniqueIdentifier: this.professorRegister.value.uniqueIdentifier

      };

      this.store.dispatch(professorActions.professorRegister(payload));
   }
}

/**
 * Method use to get form controls.
 */
get form() {
   return this.professorRegister.controls;
}
/**
 * Method is used to get faculty id from dropdown and then to retrieve departments of that faculty.
 */
facultyChanged() {
   this.professorRegister.controls.professorDepartment.setValue('');

   this.departmentService
      .getDepartmentsOfFaculty(
         this.professorRegister.value.professorFaculty ||
            this.selectedFaculty
      )
      .pipe(first())
      .subscribe(
         (data) => {
            this.departments = data.departments;
         },
         (error) => {
            console.log('error', error);
         }
      );
}
/**
 * Method is used to call a api to check if emails exist on database or not.
 */
// validateEmail() {
//    let time;
//    time = 300;
//    clearTimeout(this.timer);
//    if (this.form.professorEmail.value.length !== 0) {
//       this.timer = setTimeout(() => {
//          this.spinnerService.show();
//          this.userService
//             .checkEmail(this.form.professorEmail.value)
//             .pipe(first())
//             .subscribe(
//                (data) => {
//                   if (data.sameEmails !== 0) {
//                      this.emailExists = true;
//                   } else {
//                      this.emailExists = false;
//                   }
//                   this.spinnerService.hide();
//                },
//                (error) => {
//                   this.spinnerService.hide();
//                   console.log('error', error);
//                }
//             );
//       }, time);
//    } else {
//       this.emailExists = false;
//    }
// }
/**
 *  Method is used to get department id from dropdown and then to retrieve courses of that department.
 */
departmentChanged() {
   this.courseService
      .courses(undefined, -1, this.selectedFaculty, this.selectedDepartment)
      .pipe(first())
      .subscribe(
         (data) => {
            this.courses = data.courses;
            // Build course code map
            this.courseCodeMap.clear();
            this.courses.forEach(course => {
               if (course.code) {
                  this.courseCodeMap.set(course.code, course);
               }
            });
            this.validateExcelData(this.excelData); // Revalidate data with new course map
         },
         (error) => {
            console.log('error', error);
         }
      );
}

/**
 * Method used to download excel form for bulk uploads
 */

downloadTemplate() {
   this.excelService
      .generateGetPresignedUrl('professor-register-form.xlsx')
      .pipe(first())
      .subscribe(
         (data) => {
            saveAs(data.urlToDownload, 'professor-register-form.xlsx');
            Swal.fire(this.translate.instant('app.document_saved'), '', 'success');
         },
         (error) => {
            console.log('error', error);
         }
      );
}

/**
 * Method used to trigger file upload.
 */
upload() {
   $('.dropzone').trigger('click');
}

/**
 * Validates Excel data and returns array of errors
 */
private validateExcelData(data: any[]): void {
   this.errorFromExcel = [];
   this.previewData = [];
   const emailSet = new Set<string>();

   data.forEach((row, index) => {
      const lineNumber = index + 2;
      const errors: string[] = [];

      // Normalize the email
      const normalizedEmail = row['Email']?.toString().trim().toLowerCase() || '';

      const newRow = {
         rowNumber: lineNumber,
         'First Name': row['First Name'] || '',
         'Last Name': row['Last Name'] || '',
         'Email': normalizedEmail,
         'Course code': row['Course code'] || '',
         courses: [],
         isValid: true
      };

      // Validate Email
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!normalizedEmail || !emailRegex.test(normalizedEmail)) {
         errors.push('Invalid Email format');
      } else {
         // Check for duplicates within Excel file
         if (emailSet.has(normalizedEmail)) {
            const originalRow = this.findRowNumberForEmail(normalizedEmail);
            errors.push(`Duplicate email: ${normalizedEmail} already exists in row ${originalRow}`);
         }
         emailSet.add(normalizedEmail);
      }

      // Validate and process course codes
      if (row['Course code']) {
         const courseCodes = this.parseCourseCodes(row['Course code']);
         courseCodes.forEach(code => {
            const course = this.courseCodeMap.get(code);
            if (course) {
               newRow.courses.push(course);
            }
         });

         if (newRow.courses.length !== courseCodes.length) {
            errors.push('Some course codes are invalid');
         }
      }

      newRow.isValid = errors.length === 0;

      if (errors.length > 0) {
         this.errorFromExcel.push({
            rowNumber: lineNumber,
            errors: errors,
            isFixed: false
         });
      }

      this.previewData.push(newRow);
   });

   this.totalPages = Math.ceil(this.previewData.length / this.pageSize);
   this.currentPage = 1;
   this.showErrors = this.errorFromExcel.length > 0;
   this.showPreview = true;
   this.cd.detectChanges();
}

// Helper method to find the row number where an email first appears
private findRowNumberForEmail(email: string): number {
   for (let i = 0; i < this.previewData.length; i++) {
       if (this.previewData[i]['Email'] === email) {
           return this.previewData[i].rowNumber;
       }
   }
   return -1;
}

/**
 * Mark error as fixed
 */
markAsFixed(rowNumber: number) {
   const errorIndex = this.errorFromExcel.findIndex(err => err.rowNumber === rowNumber);
   if (errorIndex !== -1) {
      this.errorFromExcel[errorIndex].isFixed = true;
      this.fixedErrors.add(rowNumber);
      
      // Update preview data status
      const previewIndex = this.previewData.findIndex(row => row.rowNumber === rowNumber);
      if (previewIndex !== -1) {
         this.previewData[previewIndex].isValid = true;
      }
   }
}

/**
 * Check if all errors are fixed
 */
areAllErrorsFixed(): boolean {
   return this.errorFromExcel.every(error => error.isFixed);
}

/**
 * Handle file selection
 */
onFileSelected(event: any) {
   this.spinnerService.show();
   this.resetForm();
   this.fileUploaded = true;
   const file = event.target.files[0];
   this.uploadedFileName = file.name; // Store the file name
   const reader = new FileReader();
   this.cd.detectChanges();

   reader.onload = (e: any) => {
      try {
         const workbook = XLSX.read(e.target.result, { type: 'binary' });
         const firstSheetName = workbook.SheetNames[0];
         const worksheet = workbook.Sheets[firstSheetName];
         
         const jsonData = XLSX.utils.sheet_to_json(worksheet);
         this.excelData = jsonData;
         this.validateExcelData(jsonData);

         this.spinnerService.hide();
      } catch (error) {
         console.error('Excel reading error:', error);
         this.spinnerService.hide();
         this.toastrService.error(this.translate.instant('notifications.error_reading_file'));
      }
   };

   reader.readAsBinaryString(file);
}

/**
 * Reset form and clear all data
 */
resetForm() {
   this.excelData = [];
   this.previewData = [];
   this.errorFromExcel = [];
   this.showErrors = false;
   this.showPreview = false;
   this.fixedErrors.clear();
   this.fileUploaded = false;
}

/**
 * Method to upload data in chunks
 */
async uploadToServer() {
   if (!this.areAllErrorsFixed()) {
      this.toastrService.warning('Please fix all errors before uploading');
      return;
   }

   const result = await Swal.fire({
      title: 'You are about to add educators, please confirm your action by clicking "Confirm".',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#b5adad',
      confirmButtonText: this.translate.instant('app.confirm'),
      cancelButtonText: this.translate.instant('report.cancel'),
   });

   if (!result.isConfirmed) return;

   try {
      this.isUploading = true;
      this.processedCount = 0;
      this.uploadProgress = 0;
      
      // Create chunks of data (30 records per chunk)
      const chunks = this.chunkArray(this.previewData, 30); // Changed chunk size to 30
      const totalRecords = this.previewData.length;

      for (let chunk of chunks) {
         // Process each chunk
         await this.processChunk(chunk);
         
         // Update progress
         this.processedCount += chunk.length;
         this.uploadProgress = Math.round((this.processedCount / totalRecords) * 100);
         this.cd.detectChanges();
      }

      // Show success message
      this.toastrService.success(`Successfully registered ${totalRecords} educators`);
      this.resetForm();
      
   } catch (error) {
      console.error('Upload error:', error);
      this.toastrService.error('Error occurred while registering educators');
   } finally {
      this.isUploading = false;
      this.cd.detectChanges();
   }
}

/**
 * Helper method to split array into chunks
 */
private chunkArray(array: any[], size: number): any[][] {
   const chunks = [];
   for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
   }
   return chunks;
}

/**
 * Process a single chunk of data
 */
private async processChunk(chunk: any[]): Promise<void> {
   // Prepare batch data
   const batchData = chunk.map(row => ({
      professorName: `${row['First Name']} ${row['Last Name']}`,
      professorFaculty: this.selectedFaculty,
      professorDepartment: this.selectedDepartment,
      professorSubjects: row.courses,
      professorEmail: row['Email'],
      professorWords: 0,
   }));

   // Call the API to register the batch
   try {
      await this.userService.registerUsersBatch(batchData, 'professor').toPromise();
   } catch (error) {
      console.error('Batch registration error:', error);
      throw error;
   }
}

showAlert() {
   Swal.fire(
     this.translate.instant('notifications.cant_upload_file'),
     this.translate.instant('notifications.please_select_institution_and_department_then_upload_the_file'),
      'warning'
   );
}

changeStyle(index) {
   let element = document.getElementById(index);
   element.style.textDecoration = 'line-through';
}

previousPage() {
   if (this.currentPage > 1) {
      this.currentPage--;
      this.cd.detectChanges();
   }
}

nextPage() {
   const maxPage = Math.ceil(this.previewData.length / this.pageSize);
   if (this.currentPage < maxPage) {
      this.currentPage++;
      this.cd.detectChanges();
   }
}

validateRow(row: any) {
   const errors: string[] = [];
   
   // Validate First Name
   if (!this.isValidName(row['First Name'])) {
      errors.push('First name should only contain letters');
   }

   // Validate Last Name
   if (!this.isValidName(row['Last Name'])) {
      errors.push('Last name should only contain letters');
   }

   // Validate Email
   if (!this.isValidEmail(row['Email'])) {
      errors.push('Please enter a valid email address');
   } else {
      // Check for duplicates within the current data
      const duplicateInFile = this.previewData.some(
         r => r.rowNumber !== row.rowNumber && 
         r['Email'].toLowerCase() === row['Email'].toLowerCase()
      );
      
      if (duplicateInFile) {
         const originalRow = this.findRowNumberForEmail(row['Email'].toLowerCase());
         errors.push(`Duplicate email: ${row['Email']} already exists in row ${originalRow}`);
      }
   }

   row.isValid = errors.length === 0;
   row.errors = errors;
   
   // Update error list
   const errorIndex = this.errorFromExcel.findIndex(e => e.rowNumber === row.rowNumber);
   if (errors.length > 0) {
      if (errorIndex === -1) {
         this.errorFromExcel.push({
            rowNumber: row.rowNumber,
            errors: errors,
            isFixed: false
         });
      } else {
         this.errorFromExcel[errorIndex].errors = errors;
         this.errorFromExcel[errorIndex].isFixed = false;
      }
   } else if (errorIndex !== -1) {
      this.errorFromExcel.splice(errorIndex, 1);
   }

   this.cd.detectChanges();
}

isValidName(name: string | null | undefined): boolean {
   if (!name) return false;
   const nameStr = String(name); // Convert to string
   const nameRegex = /^[a-zA-Z\s]+$/;
   return nameRegex.test(nameStr.trim());
}

isValidEmail(email: string | null | undefined): boolean {
   if (!email) return false;
   const emailStr = String(email); // Convert to string
   const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
   return emailRegex.test(emailStr.trim());
}

/**
 * Method to handle page size change
 */
onPageSizeChange(newSize: number) {
   // Update page size
   this.pageSize = Number(newSize);
   
   // Calculate new total pages based on the new page size
   this.totalPages = Math.ceil(this.previewData.length / this.pageSize);
   
   // Reset to first page to avoid pagination issues
   this.currentPage = 1;
   
   console.log(`Page size: ${this.pageSize}, Total items: ${this.previewData.length}, Total pages: ${this.totalPages}`);
   
   this.cd.detectChanges();
}


/**
 * Method to get filtered data based on error status
 */
get filteredPreviewData(): any[] {
   if (this.showOnlyErrors) {
      return this.previewData.filter(row => !row.isValid);
   }
   return this.previewData;
}

/**
 * Toggle error filter
 */
toggleErrorFilter() {
   this.showOnlyErrors = !this.showOnlyErrors;
   this.currentPage = 1; // Reset to first page when filtering
   this.totalPages = Math.ceil(this.filteredPreviewData.length / this.pageSize);
}

/**
 * Method to parse course codes from Excel
 */
private parseCourseCodes(courseCodeStr: any): string[] {
   if (!courseCodeStr) return [];
   // Ensure courseCodeStr is a string
   const codeString = String(courseCodeStr);
   return codeString.split(',').map(code => code.trim());
}

/**
 * Method to update courses for a specific row
 */
updateRowCourses(row: any, selectedCourses: any[]) {
   const index = this.previewData.findIndex(r => r.rowNumber === row.rowNumber);
   if (index !== -1) {
      this.previewData[index].courses = selectedCourses;
   }
}

/**
 * Method to safely handle course selection changes
 */
onCourseSelectionChange(row: any, event: any) {
   const index = this.previewData.findIndex(r => r.rowNumber === row.rowNumber);
   if (index !== -1) {
      if (!this.previewData[index].courses) {
         this.previewData[index].courses = [];
      }

      if (Array.isArray(event)) {
         this.previewData[index].courses = [...event];
      } else if (event) {
         const courseExists = this.previewData[index].courses.some(
            course => course.id === event.id
         );

         if (!courseExists) {
            this.previewData[index].courses.push(event);
         } else {
            this.previewData[index].courses = this.previewData[index].courses.filter(
               course => course.id !== event.id
            );
         }
      }

      // Force change detection
      this.cd.detectChanges();
   }
}

removeAllErrorRows() {
   this.previewData = this.previewData.filter(row => row.isValid);
   this.errorFromExcel = [];
   this.totalPages = Math.ceil(this.previewData.length / this.pageSize);
   this.currentPage = 1; 
   this.showOnlyErrors = !this.showOnlyErrors
   this.cd.detectChanges();
}

/**
 * Method to get paginated data
 */
get paginatedData(): any[] {
   if (!this.previewData) return [];
   
   // First, filter the data if needed
   let filteredData = this.previewData;
   if (this.showOnlyErrors) {
      filteredData = this.previewData.filter(row => !row.isValid);
   }
   
   // Then paginate the filtered data
   const startIndex = (this.currentPage - 1) * this.pageSize;
   const endIndex = Math.min(startIndex + this.pageSize, filteredData.length);
   
   const paginatedResult = filteredData.slice(startIndex, endIndex);
   console.log(`Showing ${paginatedResult.length} items (${this.showOnlyErrors ? 'errors only' : 'all rows'})`);
   
   return paginatedResult;
}
}