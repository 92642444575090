import { currentUser } from './authModule/state/actions/authentication.actions';
import { Component, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LanguageService } from './services/language.service';
import { UserService } from './services/user.service';
import { SubmissionsService } from './services/submissions.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from './authModule/state/authentication.selectors';
import { BulkRegisterService } from './services/bulk-register.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentLanguage: string = localStorage.getItem('websiteLanguage');
  currentUserId: string;
  showSpinner = false;
  downloadText = '';
  // currentUser$;
  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private titleService: Title,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private userService: UserService,
    private store: Store,
    private bulkRegisterService: BulkRegisterService
  ) {
    translate.addLangs([
      'english',
      'albanian',
      'german',
      'serbian',
      'polish',
      'french',
      'turkish',
      'italian',
      'czech',
      'greek',
      'portuguese',
      'spanish',
    ]);

    // translate.setDefaultLang('en');

    // const browserLang = translate.getBrowserLang();
    // translate.use(
      //    browserLang.match(/english|shqip|german/) ? browserLang : 'english'
      // );
    }
    ngOnInit(): void {
      this.userService.spinnerObservable.subscribe(show => {
        this.showSpinner = show;
      });

      this.userService.messageObservable.subscribe(message => {
        this.downloadText = message;
      });
      // window.addEventListener('storage', (event) => {
        //    if (event.storageArea == localStorage) {
          //       let token = this.authService.getUserToken();
          //       if (
            //          token == undefined &&
            //          event.url.indexOf('individual') === -1 &&
            //          event.url.indexOf('admin') === -1
            //       ) {
              //          console.log('agneseeesaaa');

              //          this.authService.logout();
              //       }
              //    }
              // });

              this.languageService.selectedLanguage$.subscribe((language: string) => {

                this.currentLanguage = language;
                this.translate.use(language);
              });


      const appTitle = this.titleService.getTitle();

      this.router
        .events.pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => {
            const child = this.activatedRoute.firstChild;

            if(child?.snapshot['_routerState']?.url?.split('individual/')[1]?.split('/')) {
               let componentNAme =  child.snapshot['_routerState'].url.split('individual/')[1].split('/');

               console.log(componentNAme,"componentNAme");


               componentNAme = componentNAme.map(element => {
                  return element.charAt(0).toUpperCase() + element.slice(1);
               });
                return componentNAme.join(' ');

            }

            return appTitle


          })
        ).subscribe((ttl: string) => {
          this.titleService.setTitle(ttl);
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    handleBeforeUnload(event: BeforeUnloadEvent) {
      // Check if registration is in progress
      const registrationState = this.bulkRegisterService.getCurrentProgress();
      
      if (registrationState.isInProgress) {
        // Cancel the event
        event.preventDefault();
        // Chrome requires returnValue to be set
        event.returnValue = '';
        
        // Show a confirmation dialog
        return 'Registration is in progress. Are you sure you want to leave? Your progress will be lost.';
      }
      return true;
    }

   }
