// *** Angular
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

// *** Actions
import * as studentActions from '../state/student/action/student.actions';

// *** Selectors
import { getInstitutionStudentsStateDetails } from '../state/student/selector/student.selectors';

// *** Packages
import Swal from 'sweetalert2';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { FacultyService } from 'src/app/services/faculty.service';
import { UserService } from 'src/app/services/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelService } from 'src/app/services/excel.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UploadfileService } from 'src/app/individual-components/individual-uploads/upload-file/Service/uploadfile.service';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { getCurrentUser } from '../../authModule/state/authentication.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-student-form-registration',
  templateUrl: './student-form-registration.component.html',
  styleUrls: ['./student-form-registration.component.scss']
})
export class StudentFormRegistrationComponent implements OnInit {
/**
    * Variable used to know if user is using excel form or manual form
    */
excel = false;
/**
 * Variable used to store faculties of institution
 */
faculties;
/**
 * Variable used to store departments of selected faculty
 */
departments = [];
/**
 * Form group used to get user input
 */
studentRegister: FormGroup;
/**
 * Variable used to store a time for function to trigger.(x time after key is up call y function.)
 */
timer;
/**
 * Variable used to store a boolean value for validating email.
 */
emailExists: boolean = false;
/**
 * Variable used to store a boolean value for validating student index.
 */
indexExists: boolean = false;
getInstitutionStudentsStateDetails$: any;
/**
 * Variable used to store selected Faculty for bulk upload method
 */
selectedFaculty: string;
/**
 * Variable used to store selected Department for bulk upload method
 */
selectedDepartment: string;
fileToUpload: File;
showErrors: boolean = false;
errorFromExcel = [];
currentUser$: any;
user: User;
currentUserDetailsSubscriber$: any;
constructor(
   private fb: FormBuilder,
   private facultyService: FacultyService,
   private departmentService: DepartmentService,
   private store: Store,
   private userService: UserService,
   @Optional() public dialogRef: MatDialogRef<any>,
   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
   private excelService: ExcelService,
   private spinner: NgxSpinnerService,
   private uploadfileService: UploadfileService,
   private toastrService: ToastrService,
   public translate: TranslateService
) {}
ngOnDestroy(): void {
   this.getInstitutionStudentsStateDetails$.unsubscribe();
}

ngOnInit(): void {

   this.currentUser$ = this.store.select(getCurrentUser);
   this.currentUserDetailsSubscriber$ = this.store
      .select(getCurrentUser)
      .subscribe((data: User) => {
          this.user = data
      });

   this.facultyService
      .getFacultiesOfInstitution(0)
      .pipe(first())
      .subscribe(
         (data) => {
            this.faculties = data.faculties;
         },
         (error) => {
            console.log(error);
         }
      );

   this.studentRegister = this.fb.group({
      studentName: ['', [Validators.required, Validators.minLength(3)]],
      studentFaculty: ['', [Validators.required]],
      studentDepartment: ['', [Validators.required]],
      studentIndexID: [''],
      studentEmail: ['', [Validators.required, Validators.email]],
   });

   this.getInstitutionStudentsStateDetails$ = this.store
      .select(getInstitutionStudentsStateDetails)
      .subscribe((data) => {
         if (data.studentRegistered !== null) {
            if (this.dialogRef) {
               this.dialogRef.close();
            } else {
               this.studentRegister = this.fb.group({
                  studentName: [
                     '',
                     [Validators.required, Validators.minLength(3)],
                  ],
                  studentFaculty: ['', [Validators.required]],
                  studentDepartment: ['', [Validators.required]],
                  studentIndexID: [
                     ''
                  ],
                  studentEmail: [
                     '',
                     [Validators.required, Validators.email],
                  ],
               });
               this.store.dispatch(
                  studentActions.setStudentRegisterToNull()
               );
            }
         }
      });
}
/**
 * Method is used to toggle between excel upload form and manual register form.
 */
toggleForm(formTodisplay) {
   if(formTodisplay=='manual') {
      this.excel = false;
   } else {
      this.excel = true;
   }
}

/**
 * Method is used to create a course.
 */
async submit() {
   const result = await Swal.fire({
      title: 'You are about to add students, please confirm your action by clicking “Confirm”.',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#b5adad',
      confirmButtonText: this.translate.instant('app.confirm'),
      cancelButtonText: this.translate.instant('report.cancel'),
   });
   if (result.isConfirmed) {
      this.store.dispatch(
         studentActions.studentRegister({
            studentName: this.studentRegister.value.studentName,
            studentFaculty: this.studentRegister.value.studentFaculty,
            studentDepartment: this.studentRegister.value.studentDepartment,
            studentIndexID: this.studentRegister.value.studentIndexID,
            studentEmail: this.studentRegister.value.studentEmail,
         })
      );
   }
}

/**
 * Method use to get form controls.
 */
get form() {
   return this.studentRegister.controls;
}
/**
 * Method is used to get faculty id from dropdown and then to retrieve departments of that faculty.
 */
facultyChanged() {
   this.studentRegister.controls.studentDepartment.setValue('');
   this.departmentService
      .getDepartmentsOfFaculty(
         this.studentRegister.value.studentFaculty || this.selectedFaculty
      )
      .pipe(first())
      .subscribe(
         (data) => {
            this.departments = data.departments;
         },
         (error) => {
            console.log('error', error);
         }
      );
}

/**
 * Method is used to call a api to check if emails exist on database or not.
 */
// validateEmail() {
//    let time;
//    time = 300;
//    clearTimeout(this.timer);
//    if (this.form.studentEmail.value.length !== 0) {
//       this.timer = setTimeout(() => {
//          this.userService
//             .checkEmail(this.form.studentEmail.value)
//             .pipe(first())
//             .subscribe(
//                (data) => {
//                   if (data.sameEmails !== 0) {
//                      this.emailExists = true;
//                   } else {
//                      this.emailExists = false;
//                   }
//                },
//                (error) => {
//                   console.log('error', error);
//                }
//             );
//       }, time);
//    } else {
//       this.emailExists = false;
//    }
// }

/**
 * Method is used to call a api to check if emails exist on database or not.
 */
validateIndex() {
   let time;
   time = 300;
   clearTimeout(this.timer);

   if (this.form.studentIndexID.value.length !== 0) {
      this.timer = setTimeout(() => {
         this.userService
            .checkIndex(this.form.studentIndexID.value)
            .pipe(first())
            .subscribe(
               (data) => {
                  if (data.sameIndex !== 0) {
                     this.indexExists = true;
                  } else {
                     this.indexExists = false;
                  }
               },
               (error) => {
                  console.log('error', error);
               }
            );
      }, time);
   } else {
      this.indexExists = false;
   }
}

/**
 * Method used to download excel form for bulk uploads
 */

downloadTemplate() {
   this.excelService
      .generateGetPresignedUrl('student-register-form.xlsx')
      .pipe(first())
      .subscribe(
         (data) => {
            saveAs(data.urlToDownload, 'student-register-form.xlsx');
            Swal.fire(this.translate.instant('app.document_saved'), '', 'success');
         },
         (error) => {
            console.log('error', error);
         }
      );
}

/**
 * Method used to trigger file upload.
 */
upload() {
   $('.dropzone').trigger('click');
}

showAlert() {
   Swal.fire(
     this.translate.instant('notifications.cant_upload_file'),
      this.translate.instant('notifications.please_select_institution_and_department_then_upload_the_file'),
      'warning'
   );
}

/**
 * Method used to upload, update & preview file at upload form.
 */
onFileSelected(event: any) {
   this.spinner.show();
   this.errorFromExcel = [];
   this.showErrors = false;
   this.fileToUpload = <File>event.target.files[0];
   this.excelService
      .generatePutPresignedUrl('Student')
      .pipe(first())
      .subscribe(
         (response) => {
            this.uploadfileService
               .uploadfileAWSS3(
                  response.presignedS3UrlUpload,
                  this.fileToUpload
               )
               .pipe(first())
               .subscribe(
                  (data) => {
                     this.excelService
                        .registerStudents(
                           response.key,
                           this.selectedFaculty,
                           this.selectedDepartment
                        )
                        .pipe(first())
                        .subscribe(
                           (data) => {
                              this.spinner.hide();
                              console.log('data', data);
                              if (data.errors.length !== 0) {
                                 this.errorFromExcel = data.errors;
                                 this.showErrors = true;
                              } else {
                                 this.toastrService.success(data.message);
                              }
                           },
                           (error) => {
                              console.log('error', error);
                              this.spinner.hide();
                           }
                        );
                  },
                  (error) => {
                     console.log('error', error);
                     this.spinner.hide();
                  }
               );
         },
         (error) => {
            console.log('error', error);
            this.spinner.hide();
         }
      );
}
changeStyle(index) {
   let element = document.getElementById(index);
   element.style.textDecoration = 'line-through';
}
}
