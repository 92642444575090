import { Component, Input, OnInit, HostListener, ElementRef, ViewChild, Output, EventEmitter, OnChanges, SimpleChanges, NgZone } from '@angular/core';

export interface Handle {
  value: number;
  min: number;
  max: number;
  label?: string;
  hardMinimum?: number;
  hardMaximum?: number;
}

export interface SliderRange {
  color: string;
  label?: string;
}

@Component({
  selector: 'app-custom-slider',
  templateUrl: './custom-slider.component.html',
  styleUrls: ['./custom-slider.component.scss']
})
export class CustomSliderComponent implements OnInit, OnChanges {
  @Input() sliderId: string = 'default';

  @Input() handles: Handle[] = [
    { value: 40, min: 0, max: 79 },
    { value: 80, min: 41, max: 100 }
  ];

  @Input() rangeColors: SliderRange[] = [
    { color: '#BCBCBC', label: 'Below Threshold' },
    { color: '#7ABDFF', label: 'Altered Text' },
    { color: '#FFE4E4', label: 'Exact Match' }
  ];

  @Input() showPercentageMarkers = true;
  @Input() showHandleValues = true;
  @Input() handleColor = '#006495';
  @Input() trackHeight = '15px';
  @Input() handleHeight = '30px';
  @Input() handleWidth = '5px';

  @Input() defaultValues: number[] = [];

  @Output() handleChange = new EventEmitter<Handle[]>();

  activeHandle: number | null = null;
  isDragging = false;
  @ViewChild('sliderTrack') sliderTrack: ElementRef;

  @Input() handleColors: string[] = ['#006495', '#006495']; // Default colors for both handles

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    this.updateTrackColors();
  }

  ngAfterViewInit() {
    // Set initial colors
    this.updateTrackColors();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['handles'] || changes['rangeColors']) && !changes['handles']?.firstChange) {
      this.updateTrackColors();
    }
  }

  private updateTrackColors() {
    if (!this.sliderTrack) return;

    const trackElement = this.sliderTrack.nativeElement;

    // Clear existing styles first
    trackElement.style.removeProperty('--slider-altered-start');
    trackElement.style.removeProperty('--slider-exact-start');

    // Force reflow
    void trackElement.offsetWidth;

    // Set range colors with unique identifiers
    this.rangeColors.forEach((range, index) => {
      trackElement.style.setProperty(`--slider-range-${index}-color`, range.color);
    });

    const alteredStart = this.handles[0].value;
    const exactStart = this.handles[1].value;

    // Set new positions with unique identifiers
    trackElement.style.setProperty('--slider-altered-start', `${alteredStart}%`);
    trackElement.style.setProperty('--slider-exact-start', `${exactStart}%`);

    // Force another reflow to ensure updates
    void trackElement.offsetWidth;
  }

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging && this.activeHandle !== null) {
      // Get the slider wrapper element once
      const sliderWrapper = this.sliderTrack.nativeElement.parentElement;
      const sliderRect = sliderWrapper.getBoundingClientRect();

      // Calculate percentage based on mouse position relative to slider bounds
      const percentage = Math.min(Math.max(
        ((event.clientX - sliderRect.left) / sliderRect.width) * 100,
        0
      ), 100);

      const roundedPercentage = Math.round(percentage);
      this.updateHandlePosition(this.activeHandle, roundedPercentage);
    }
  }

  @HostListener('window:mouseup')
  onMouseUp() {
    if (this.isDragging) {
      this.isDragging = false;
      this.activeHandle = null;
      this.handleChange.emit(this.handles);
    }
  }

  startDragging(event: MouseEvent, index: number) {
    const handle = this.handles[index];

    if (handle.hardMinimum !== undefined && handle.value <= handle.hardMinimum) {
      return;
    }
    if (handle.hardMaximum !== undefined && handle.value >= handle.hardMaximum) {
      return;
    }

    this.isDragging = true;
    this.activeHandle = index;
    event.preventDefault();
  }

  private updateHandlePosition(index: number, percentage: number) {
    const handle = this.handles[index];
    const prevHandle = this.handles[index - 1];
    const nextHandle = this.handles[index + 1];

    let newValue = percentage;

    // Enforce min/max constraints
    newValue = Math.max(newValue, handle.min);
    newValue = Math.min(newValue, handle.max);

    // Enforce handle order constraints
    if (prevHandle) {
      newValue = Math.max(newValue, prevHandle.value + 1);
    }
    if (nextHandle) {
      newValue = Math.min(newValue, nextHandle.value - 1);
    }

    // Create new handle with updated value
    this.handles[index] = {
      ...handle,
      value: newValue
    };
  }

  onPercentageClick(percentage: number) {
    // Check if percentage is in a disabled region
    const isInDisabledRegion = this.handles.some(handle => {
      if (handle.hardMinimum !== undefined && percentage <= handle.hardMinimum) {
        return true;
      }
      if (handle.hardMaximum !== undefined && percentage >= handle.hardMaximum) {
        return true;
      }
      return false;
    });

    if (!isInDisabledRegion) {
      const closestHandleIndex = this.handles.reduce((closest, handle, index) => {
        if (closest === -1) {
          if (!this.isHandleAtHardLimit(handle)) {
            return index;
          }
          return -1;
        }

        if (this.isHandleAtHardLimit(handle)) {
          return closest;
        }

        const distance = Math.abs(handle.value - percentage);
        const closestDistance = Math.abs(this.handles[closest].value - percentage);
        return distance < closestDistance ? index : closest;
      }, -1);

      if (closestHandleIndex !== -1) {
        this.updateHandlePosition(closestHandleIndex, percentage);
        this.handleChange.emit(this.handles);
      }
    }
  }

  getHandleColorClass(percentage: number): string {
    // If percentage is less than first handle
    if (percentage <= this.handles[0].value) {
      return 'hover-range-0';
    }
    // If percentage is between handles
    else if (percentage <= this.handles[1].value) {
      return 'hover-range-1';
    }
    // If percentage is greater than second handle
    else {
      return 'hover-range-2';
    }
  }

  isOutOfHardLimit(marker: number) {
    return this.handles.some(handle => {
      if (handle.hardMinimum !== undefined && marker < handle.hardMinimum) {
        return true;
      }
      if (handle.hardMaximum !== undefined && marker > handle.hardMaximum) {
        return true;
      }
      return false;
    });
  }

  @HostListener('click', ['$event'])
  onSliderClick(event: MouseEvent) {
    // Stop event propagation
    event.stopPropagation();

    const target = event.target as HTMLElement;
    const sliderWrapper = target.closest('.slider-wrapper');

    // Check if the click is within this component's slider
    if (!this.sliderTrack.nativeElement.contains(event.target)) {
      return;
    }

    if (sliderWrapper && !target.classList.contains('slider-handle')) {
      const sliderRect = sliderWrapper.getBoundingClientRect();
      const percentage = Math.min(Math.max(
        ((event.clientX - sliderRect.left) / sliderRect.width) * 100,
        0
      ), 100);

      const roundedPercentage = Math.round(percentage);

      // Find the closest handle that is not at a hard limit
      const distances = this.handles
        .map((handle, index) => ({
          index,
          distance: Math.abs(handle.value - roundedPercentage),
          isAtHardLimit: this.isHandleAtHardLimit(handle)
        }))
        .filter(handle => !handle.isAtHardLimit); // Filter out handles at hard limits

      if (distances.length === 0) {
        return; // All handles are at hard limits
      }

      const closestHandle = distances.reduce((prev, curr) =>
        prev.distance < curr.distance ? prev : curr
      );

      // Update the position
      this.updateHandlePosition(closestHandle.index, roundedPercentage);

      // Schedule the update
      setTimeout(() => {
        this.updateTrackColors();
        this.handleChange.emit([...this.handles]);
      }, 0);
    }
  }

  resetSlider() {
    if (this.defaultValues.length === this.handles.length) {
      // Create new handles array with default values
      this.handles = this.handles.map((handle, index) => ({
        ...handle,
        value: this.defaultValues[index]
      }));

      // Schedule the update to run after the current execution context
      setTimeout(() => {
        this.updateTrackColors();
        this.handleChange.emit([...this.handles]);
      }, 0);
    }
  }

  // Helper method to check if handle is at hard limit
  private isHandleAtHardLimit(handle: Handle): boolean {
    return (handle.hardMinimum !== undefined && handle.value <= handle.hardMinimum) ||
           (handle.hardMaximum !== undefined && handle.value >= handle.hardMaximum);
  }

  getHandleColor(index: number): string {
    // If handle is at hard limit, return disabled color
    if (this.isHandleAtHardLimit(this.handles[index])) {
      return '#bcbcbc'; // Disabled color
    }
    // Return the specific handle color if available, otherwise fall back to default
    return this.handleColors[index] || this.handleColor;
  }
}
