<section style="position: relative;" class="flex register-student container">
    <main [ngClass]="{blurryBackground:user?.Institution?.InstitutionLicense?.length == 0 && (user.roleId == 3)}">
       <h4 class="bold">{{ 'app.register_students' | translate }}</h4>
       <!-- <div class="nav">
          <a (click)="toggleForm('manual')" [ngClass]="{ active: this.excel === false }"
             >{{ 'general.manual_form' | translate }}</a
          >
          <a (click)="toggleForm('bulk')" [ngClass]="{ active: this.excel === true }" class="disable"
             >{{ 'app.upload.bulk_form' | translate }}</a
          >
       </div> -->
       <ng-container *ngIf="this.excel === false">
          <form action="" [formGroup]="studentRegister">
             <h5>{{ 'general.full_name' | translate }}</h5>
             <input formControlName="studentName" type="text" />
             <div
                *ngIf="
                   studentRegister.controls.studentName.touched &&
                   studentRegister.controls.studentName.invalid
                "
                class="invalid"
             >
                {{ 'general.full_name' | translate }} {{ 'aria-labels.is_required' | translate }}
             </div>
             <div
                *ngIf="studentRegister.controls.studentName.errors?.minlength"
                class="invalid"
             >
                {{ 'general.full_name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}.
             </div>
             <h5>{{ 'app.institution' | translate }}</h5>
             <select
                formControlName="studentFaculty"
                (change)="facultyChanged()"
             >
                <option selected disabled value="">{{ 'general.select_institution' | translate }}</option>
                <ng-container *ngFor="let faculty of this.faculties">
                   <option value="{{ faculty.id }}">
                      {{ faculty.name }}
                   </option>
                </ng-container>
             </select>
             <div
                *ngIf="
                   studentRegister.controls.studentFaculty.touched &&
                   studentRegister.controls.studentFaculty.invalid
                "
                class="invalid"
             >
                {{ 'general.student' | translate }} {{ 'app.institution' | translate }} {{ 'aria-labels.is_required' | translate }}*
             </div>
             <h5>{{ 'general.department' | translate }}</h5>
             <select
                disabled
                *ngIf="
                   studentRegister.controls.studentFaculty.value == '' ||
                   studentRegister.controls.studentFaculty == undefined
                "
                name=""
                id=""
             >
                <option value="">{{ 'general.select_institution_first' | translate }}</option>
             </select>
             <select
                *ngIf="
                   studentRegister.controls.studentFaculty.value !== '' &&
                   studentRegister.controls.studentFaculty !== undefined
                "
                formControlName="studentDepartment"
             >
                <option selected disabled value="">{{ 'general.select_department' | translate }}</option>
                <ng-container *ngFor="let department of this.departments">
                   <option value="{{ department.id }}">
                      {{ department.name }}
                   </option>
                </ng-container>
             </select>
             <div
                *ngIf="
                   studentRegister.controls.studentDepartment.touched &&
                   studentRegister.controls.studentDepartment.invalid
                "
                class="invalid"
             >
                {{ 'general.student' | translate }} {{ 'general.department' | translate }} {{ 'aria-labels.is_required' | translate }}*
             </div>
             <h5>{{ 'app.student_id_optional' | translate }}</h5>
             <input
                formControlName="studentIndexID"
                (ngModelChange)="validateIndex()"
                type="text"
             />
             <div *ngIf="this.indexExists" class="invalid">
                {{ 'app.student_id_already_exists' | translate }}*
             </div>
             <!-- <h5>{{ 'general.phone' | translate }}</h5>
             <input formControlName="studentPhoneNumber" type="text" />
             <div
                *ngIf="
                   studentRegister.controls.studentPhoneNumber.touched &&
                   studentRegister.controls.studentPhoneNumber.invalid
                "
                class="invalid"
             >
                {{ 'general.student' | translate }} {{ 'app.contact_number' | translate }} {{ 'general.end_date' | translate }}*
             </div>
             <div
                *ngIf="
                   studentRegister.controls.studentPhoneNumber.errors?.minlength
                "
                class="invalid"
             >
                {{ 'general.student' | translate }} {{ 'app.contact_number' | translate }} {{ 'app.should_contain_at_least_6_characters' | translate }}!
             </div> -->
             <h5>{{ 'general.email' | translate }}</h5>
             <input
                formControlName="studentEmail"
                type="email"
             />
             <!-- <div *ngIf="this.emailExists" class="invalid">
                Email already exists, please try another one.*
             </div> -->
             <div
                *ngIf="
                   studentRegister.controls.studentEmail.touched &&
                   studentRegister.controls.studentEmail.invalid
                "
                class="invalid"
             >
                {{ 'general.student' | translate }} {{ 'login.email_required' | translate }}*
             </div>
             <div
                *ngIf="studentRegister.controls.studentEmail.errors?.email"
                class="invalid"
             >
             {{ 'app.student_email_is_not_valid' | translate }}!
             </div>
             <div class="center">
                <button
                   [disabled]="
                      studentRegister.invalid ||
                      this.emailExists ||
                      this.indexExists
                   "
                   [ngClass]="{
                      disable:
                         studentRegister.invalid ||
                         this.emailExists ||
                         this.indexExists
                   }"
                   class="submit"
                   type="submit"
                   (click)="submit()"
                >
                   {{ 'app.confirm' | translate }}
                </button>
             </div>
          </form>
       </ng-container>
       <ng-container *ngIf="this.excel === true">
          <div class="content">
             <div>
                <select
                   (change)="facultyChanged()"
                   [(ngModel)]="this.selectedFaculty"
                >
                   <option selected disabled value="">{{ 'general.select_institution' | translate }}</option>
                   <ng-container *ngFor="let faculty of this.faculties">
                      <option value="{{ faculty.id }}">
                         {{ faculty.name }}
                      </option>
                   </ng-container>
                </select>
                <div
                   class="border"
                   (click)="downloadTemplate()"
                   style="cursor: pointer"
                >
                   <h6>{{ 'app.download_template' | translate }}</h6>
                   <div>
                      <i class="fas fa-download"></i>
                      <p>
                         Download and fill the relevant information within the
                         template
                      </p>
                   </div>
                </div>
             </div>
             <div>
                <select
                   disabled
                   *ngIf="
                      this.selectedFaculty == '' ||
                      this.selectedFaculty == undefined
                   "
                >
                   <option value="">{{ 'general.select_institution_first' | translate }}</option>
                </select>
                <select
                   *ngIf="
                      this.selectedFaculty !== '' &&
                      this.selectedFaculty !== undefined
                   "
                   [(ngModel)]="this.selectedDepartment"
                >
                   <option selected disabled value="">{{ 'general.select_department' | translate }}</option>
                   <ng-container *ngFor="let department of this.departments">
                      <option value="{{ department.id }}">
                         {{ department.name }}
                      </option>
                   </ng-container>
                </select>
                <div
                   class="border"
                   (click)="
                      this.selectedFaculty !== undefined &&
                      this.selectedDepartment !== undefined
                         ? upload()
                         : showAlert()
                   "
                   style="cursor: pointer"
                >
                   <div>
                      <h6>{{ 'general.upload_template' | translate }}</h6>
                      <i class="fas fa-upload"></i>
                   </div>
                   <p>
                      Upload the filled template to migrate your data
                      automatically
                   </p>
                </div>
                <input
                   class="dropzone"
                   type="file"
                   name=""
                   id=""
                   (change)="onFileSelected($event)"
                   accept=".xlsx"
                   style="display: none"
                />
             </div>
          </div>
       </ng-container>
       <ng-container *ngIf="this.excel === true && this.showErrors">
          <div class="content">
             <table>
                <tr>
                   <th>
                      <h5 class="bold">#</h5>
                   </th>
                   <th>
                      <h5 class="bold">{{ 'general.error' | translate }}</h5>
                   </th>
                   <th>
                      <h5 class="bold">{{ 'app.mark_as_fixed' | translate }}</h5>
                   </th>
                </tr>
                <tr *ngFor="let error of this.errorFromExcel; let i = index">
                   <td>
                      <h5>
                         {{ i + 1 }}
                      </h5>
                   </td>
                   <td>
                      <h5 id="{{ i + 1 }}">
                         {{ error }}
                      </h5>
                   </td>
                   <td>
                      <h5 (click)="changeStyle(i + 1)" style="cursor: pointer">
                         Mark
                      </h5>
                   </td>
                </tr>
             </table>
          </div>
       </ng-container>
    </main>
    <div class="noActiveLicense" *ngIf="user?.Institution?.InstitutionLicense?.length == 0 && (user.roleId == 3)">
       You should have an active license
     </div>
 </section>
 