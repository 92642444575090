<header>
   <div class="flex container">
      <img
         class="logo"
         src="../../assets/images/Group (2).png"
         alt=""
         (click)="goHome()"
      />
      <div class="flex">
         <h5 class="totalCredits" *ngIf="user?.roleId == 2">
            <span
               >Available {{ 'general.credits' | translate }}: {{ user?.wordsToUse }}</span
            >
            <span *ngIf="user?.institutionId !== null"
               >{{ 'general.available_documents' | translate }}: {{ user?.documentsToUse }}</span
            >
            <span *ngIf="user?.roleId == 6" style="color: white">.</span>
         </h5>
         <!-- <button routerLink="register" class="reportBtn" *ngIf="user?.roleId ==  5">
            Join a class
         </button> -->
         <!-- <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdhEKlxtvLYDHV5YWPTTgbQ7lvakrutQrZYUybELi3nDdzFsg/viewform?usp=sf_link" class="reportBtn">
            Feedback Form
         </a> -->
         <app-translate></app-translate>
         <div class="flex relative">
            <i class="fas fa-user-alt"></i>
            <h5 (click)="expandMenu()" #name>
               {{ user?.name }}
            </h5>
            <div class="log-out">
               <i
                  #chevron
                  (click)="expandMenu()"
                  class="fas fa-chevron-down"
                  [ngClass]="{ chevronUp: expandedMenu }"
               ></i>
            </div>
            <div class="menu" [ngClass]="{ expandedMenu: expandedMenu, logged_from_app: isLoggedInFromApp, logged_from_lti: !isLoggedInFromApp }">
               <h3 routerLink="profile/personal-information" class="profile" routerLinkActive="active">
                  <i class="fas fa-user"></i> {{ 'general.account' | translate }}
               </h3>
               <h3 (click)="goToHelpCenter()" class="help_center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#595959" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9.08997 8.99959C9.32507 8.33126 9.78912 7.7677 10.3999 7.40873C11.0107 7.04975 11.7289 6.91853 12.4271 7.0383C13.1254 7.15808 13.7588 7.52112 14.215 8.06312C14.6713 8.60512 14.921 9.29112 14.92 9.99959C14.92 11.9996 11.92 12.9996 11.92 12.9996" stroke="#595959" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 17H12.01" stroke="#595959" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                   {{ 'general.help_center' | translate }}
               </h3>
               <h3 (click)="signOut()" class="sing_out" *ngIf="this.isLoggedInFromApp">
                  <i class="fas fa-sign-out-alt"></i> {{ 'app.log_out' | translate }}
               </h3>
            </div>
         </div>
      </div>
   </div>
</header>
<!-- <div [ngClass]="{removeInfoIndex:removeInfoIndex}" *ngIf="user?.Institution?.InstitutionLicense?.length == 0 && (user.roleId == 3 || user.roleId == 11)" class="notifyInstitution">
  <div></div>
  <span> {{ 'other.we_are_reviewing_institutional_information' | translate }}.</span>
   <i (click)="removeInfo()" class="far fa-times-circle"></i>
</div> -->

<div [ngClass]="{removeInfoIndex:removeInfoIndex}" *ngIf="user.roleId == 2" class="notifyInstitution">
   <div></div>
   <span>{{ 'app.ai.introducing_ai_content_prediction' | translate }}! <a routerLink="/individual/detector">{{ 'app.try_it_out_for_free_now' | translate }}.<i class="fas fa-external-link-alt"></i></a> </span>
    <i (click)="removeInfo()" class="fas fa-times-circle"></i>
 </div>

<!-- <div [ngClass]="{removeInfoIndex:removeInfoIndex}" *ngIf="user?.Institution?.InstitutionLicense[0]?.offerId == 'free' && user.roleId == 3" class="notifyInstitution">
   <span></span>
   <span>{{ 'other.you_are_on_free_trial' | translate }}</span>
   <i (click)="removeInfo()" class="far fa-times-circle"></i>
</div> -->
