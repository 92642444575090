<div class="d-flex justify-flex-end">
   <div class="dropdown">
      <button class="dropbtn">
         <i class="fa fa-download"></i> {{ 'general.export_data' | translate }}
      </button>
      <div class="dropdown-content">
         <a (click)="exportToExcel()">{{ 'general.export_as_excel' | translate }} (.xlsx)</a>
         <a (click)="exportToPDF()">{{ 'general.export_as_pdf' | translate }}</a>
         <a (click)="exportToCSV()">{{ 'general.export_as_csv' | translate }}</a>
      </div>
   </div>
</div>
<br>
<main>
   <div class="scroll white-bg border">
      <h3 *ngIf="
            !(
               this.courses?.totalSubjects == 0 &&
               name == undefined &&
               generation == -1 &&
               departmentId == -1 &&
               professorId == '-1' &&
               status == '-1' &&
               type == -1 &&
               facultyId == '-1'
            )
         " class="filtersContainer">
         <h4>{{ 'general.subjects' | translate }}</h4>
         <div class="selectors">
            <div [ngClass]="{
               'generationFilter': generation != '-1'}">
               <select [(ngModel)]="generation" name="" id="" (change)="filterCourses()">
                  <option value="-1" selected disabled>
                     {{ 'app.select_academic_year' | translate }}
                  </option>
                  <option value="g2021">2020/2021</option>
                  <option value="g2122">2021/2022</option>
                  <option value="g2223">2022/2023</option>
                  <option value="g2324">2023/2024</option>
                  <option value="g2425">2024/2025</option>
                  <option value="g2526">2025/2026</option>
                  <option value="g2627">2027/2028</option>
                  <option>{{ 'general.all' | translate }}</option>
               </select>
            </div>
            <!-- <div>
               <select [(ngModel)]="facultyId" (change)="selectedFaculty()">
                  <option value="-1" selected disabled>
                     {{ 'app.filter_by_institution' | translate }}
                  </option>
                  <ng-container *ngFor="let faculty of this.faculties">
                     <option value="{{ faculty.id }}">
                        {{ faculty.name }}
                     </option>
                  </ng-container>
                  <option value="">{{ 'general.all' | translate }}</option>
               </select>
            </div> -->
            <!-- <div>
               <select
                  disabled
                  *ngIf="facultyId == '' || facultyId == '-1'"
                  name=""
                  id=""
               >
                  <option value="">{{ 'general.select_institution_first' | translate }}</option>
               </select>
               <select
                  *ngIf="facultyId !== '' && facultyId !== '-1'"
                  [(ngModel)]="departmentId"
                  (change)="filterCourses()"
               >
                  <option value="-1" selected disabled>
                     {{ 'app.filter_by_department' | translate }}
                  </option>
                  <ng-container *ngFor="let department of this.departments">
                     <option value="{{ department.id }}">
                        {{ department.name }}
                     </option>
                  </ng-container>
                  <option value="">{{ 'general.all' | translate }}</option>
               </select>
            </div> -->
            <div [ngClass]="{
               'selectedOptionBorder': professorId !== '-1'
            }">
               <select [(ngModel)]="professorId" (change)="filterCourses()">
                  <option value="-1" selected disabled>
                     {{ 'app.filter_by_educator' | translate }}
                  </option>
                  <ng-container *ngFor="let professor of this.professors">
                     <option value="{{ professor.id }}">
                        {{ professor.name }}
                     </option>
                  </ng-container>

                  <option>{{ 'general.all' | translate }}</option>
               </select>
            </div>
            <div [ngClass]="{
                  'selectedOptionBorder': status !== '-1'
               }">
               <select [(ngModel)]="status" (change)="filterCourses()" name="" id="">
                  <option value="-1" selected disabled>
                     {{ 'app.select_status_to_filter' | translate }}
                  </option>
                  <option value="1">{{ 'general.active' | translate }}</option>
                  <option value="0">{{ 'general.inactive' | translate }}</option>
                  <option>{{ 'general.all' | translate }}</option>
               </select>
            </div>
            <!-- <div>
               <select
                  [(ngModel)]="type"
                  (change)="filterCourses()"
                  name=""
                  id=""
               >
                  <option value="-1" selected disabled>{{ 'app.filter_by_type' | translate }}</option>
                  <option value="1">{{ 'general.mandatory' | translate }}</option>
                  <option value="2">{{ 'general.elective' | translate }}</option>
                  <option value="">{{ 'general.all' | translate }}</option>
               </select>
            </div> -->
            <div [ngClass]="{
               'inputBorder': name?.length != 0
            }">
               <input [placeholder]="'placeholders.search_by_name' | translate" [(ngModel)]="name"
                  (ngModelChange)="titleFilter()" type="text" name="searchByName" />
            </div>
            <button class="reportBtn" (click)="resetFilter()">{{ 'general.reset' | translate }}</button>
         </div>
      </h3>
      <div *ngIf="this.courses?.totalSubjects != 0" class="scroll" #contentToCapture>
         <table>
            <tr>
               <th>{{ 'general.name' | translate }}</th>
               <th>Subject ID</th>
               <!-- <th>{{ 'app.institution' | translate }}</th>
               <th>{{ 'general.department' | translate }}</th> -->
               <th>{{ 'general.educators' | translate }}</th>
               <!-- <th>
                  {{ 'general.id' | translate }}
               </th> -->
               <th>{{ 'general.no_of_students' | translate }}</th>
               <th>{{ 'general.academic_year' | translate }}</th>
               <th>{{ 'general.type' | translate }}</th>
               <th>{{ 'general.status' | translate }}</th>
               <th>{{ 'general.edit' | translate }}</th>
            </tr>
            <ng-container *ngFor="
                  let course of this.courses?.subjects
                     | paginate
                        : {
                             itemsPerPage: pageSize,
                             currentPage: page,
                             totalItems: this.courses?.totalSubjects
                          }
               ">
               <tr class="shadow">
                  <td>
                     {{ course.title }}
                  </td>
                  <td>
                     {{ course.code }}
                  </td>
                  <!-- <td>
                     {{ course.Department.Faculty.name }}
                  </td>
                  <td>
                     {{ course.Department.name }}
                  </td> -->
                  <td>
                     <ng-container *ngIf="course.professorCourses.length > 0">
                        <ng-container *ngFor="let professor of course.professorCourses">
                           {{ professor.User.name }},
                        </ng-container>
                     </ng-container>
                     <ng-container *ngIf="course.professorCourses.length === 0">
                        -- / --
                     </ng-container>
                  </td>
                  <!-- <td>
                     {{ course.code }}
                  </td> -->
                  <td>
                     {{ course.students }}
                  </td>
                  <td>
                     {{ '20' + course.generation.substring(1, 3) }}/{{
                     '20' + course.generation.substring(3, 5)
                     }}
                  </td>
                  <td>
                     <ng-container *ngIf="course.type === 1">
                        {{ 'general.mandatory' | translate }}
                     </ng-container>
                     <ng-container *ngIf="course.type === 2">
                        {{ 'general.elective' | translate }}
                     </ng-container>
                  </td>
                  <td>
                     <ng-container *ngIf="course.status"> {{ 'general.active' | translate }} </ng-container>
                     <ng-container *ngIf="!course.status">
                        {{ 'general.inactive' | translate }}
                     </ng-container>
                  </td>
                  <td>
                     <i (click)="this.openModal(course.id)" class="far fa-edit editButton"></i>
                  </td>
               </tr>
            </ng-container>
         </table>
      </div>
      <div class="zeroState" *ngIf="
            this.courses?.totalSubjects == 0 &&
            (name != undefined ||
               facultyId != '-1' ||
               generation != -1 ||
               departmentId != -1 ||
               professorId != '-1' ||
               status != '-1' ||
               type != -1)
         ">
         {{ 'new_entries.no_subjects_found' | translate }}
      </div>
      <div class="zero-state" *ngIf="
            this.courses?.totalSubjects == 0 &&
            name == undefined &&
            facultyId == '-1' &&
            generation == -1 &&
            departmentId == -1 &&
            professorId == '-1' &&
            status == '-1' &&
            type == -1
         ">
         <!-- <img src="../../assets/images/icona-image.png" alt="" /> -->
         <h5 class="bold">
            {{ 'app.there_are_no_subjects_registered_in_your_instutition' | translate }} yet.<br /><span
               class="clickable" routerLink="/administrator/register-module/course-register">{{ 'general.click_here' |
               translate }}</span>
            to begin registering subjects.
         </h5>
      </div>

      <div class="paginationContainer">
         <div *ngIf="this.courses?.totalSubjects != 0">
            <!-- <button class="clone" (click)="openGenerationChangeModal()">
               <i class="fas fa-copy mr-5"></i>{{ 'app.subject.subject_wizard' | translate }}
            </button> -->
         </div>
         <pagination-controls *ngIf="this.courses?.totalSubjects != 0" class="num" (pageChange)="changePage($event)"
            responsive="true" previousLabel="" nextLabel=""></pagination-controls>
         <!-- <div>
         <button class="reportBtn" (click)="registerSubjectModal()">
            <i class="fas fa-plus mr-5"></i>{{ 'app.register_subjects' | translate }}
         </button>
      </div> -->
      </div>
   </div>
</main>
