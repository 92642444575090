import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-professor-register',
   templateUrl: './professor-register.component.html',
   styleUrls: ['./professor-register.component.scss'],
})
export class ProfessorRegisterComponent implements OnInit {
   
   constructor() {}
  
   ngOnInit(): void {}
   
}