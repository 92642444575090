<ng-container>
    <div class="content" role="main">
        <!-- Step Indicator -->
        <div class="step-indicator">
            <div class="step" 
                 [class.active]="currentStep === 1" 
                 [class.completed]="currentStep > 1"
                 (click)="currentStep > 1 && goToStep(1)">
                <div class="step-number" [class.completed]="currentStep > 1">1</div>
                <span>Upload File</span>
            </div>
            <div class="step" 
                 [class.active]="currentStep === 2" 
                 [class.completed]="currentStep > 2"
                 (click)="currentStep > 2 && goToStep(2)">
                <div class="step-number" [class.completed]="currentStep > 2">2</div>
                <span>Assign Faculty and Department</span>
            </div>
            <div class="step" 
                 [class.active]="currentStep === 3">
                <div class="step-number">3</div>
                <span>Manage Data</span>
            </div>
        </div>

        <!-- Each step content wrapped in step-content -->
        <div class="step-content" *ngIf="currentStep === 1">
            <!-- Step 1: File Upload -->
            <div class="upload-section">
                <div class="upload-box" 
                     (click)="upload()"
                     [class.dragging]="isDragging"
                     (dragenter)="onDragEnter($event)"
                     (dragleave)="onDragLeave($event)"
                     (dragover)="onDragOver($event)"
                     (drop)="onDrop($event)">
                    <div class="upload-icon">
                        <img src="../../../assets/images/upload_file_new.png" alt="" aria-hidden="true" />
                    </div>
                    <div class="upload-text">
                        <a href="#" class="upload-link" (click)="$event.preventDefault()">{{ 'bulk_register.upload.title' | translate }}</a>
                        {{ 'bulk_register.upload.description' | translate }}
                        <p class="details-text">{{ 'bulk_register.upload.details' | translate }}</p>
                    </div>
                </div>

                <!-- Hidden file input -->
                <input class="dropzone" 
                       type="file" 
                       (change)="onFileSelected($event)" 
                       accept=".xlsx" 
                       style="display: none" />

                <div class="template-section">
                    <div class="template-info">
                        <div class="info-icon">
                            <i class="fas fa-info-circle"></i>
                        </div>
                        <div class="template-text">
                            <h6>{{ 'bulk_register.upload.template.title.' + registrationTypeKey | translate }}</h6>
                            <p>{{ 'bulk_register.upload.template.description.' + registrationTypeKey | translate }}</p>
                            <p><a href="#"> {{ 'new_entries.get_more_info' | translate }}</a></p>
                        </div>
                    </div>
                    <button class="download-btn" (click)="downloadTemplate()">
                        {{ 'bulk_register.upload.template.button' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="step-content" *ngIf="currentStep === 2">
            <!-- Step 2: Faculty and Department Selection -->
            <div class="file-info-bar">
                <div class="file-name">
                    <span>{{uploadedFileName || 'Institution_Educator.xlsx'}}</span>
                    <button class="remove-btn" 
                            (click)="removeFile()"
                            aria-label="Remove file and return to upload">
                        <img src="assets/images/icons/trash.svg" alt="" srcset="">
                    </button>
                </div>
            </div>

            <div class="assign-section">
                <h3>{{ 'bulk_register.faculty_assignment.title' | translate }}</h3>
                <p class="section-description">
                    {{ 'bulk_register.faculty_assignment.description' | translate }}
                </p>
                
                <div class="select-container">
                    <div class="select-group">
                        <label>{{ 'bulk_register.faculty_assignment.select_faculty' | translate }}</label>
                        <div class="custom-select" (clickOutside)="closeFacultyDropdown()">
                            <div class="select-trigger" (click)="toggleFacultyDropdown()">
                                <span>{{selectedFacultyName || 'Select Faculty'}}</span>
                                <i class="fas fa-chevron-down" [class.open]="isFacultyOpen"></i>
                            </div>
                            <div class="select-dropdown" *ngIf="isFacultyOpen">
                                <div class="select-option" 
                                     *ngFor="let faculty of faculties"
                                     (click)="selectFaculty(faculty)">
                                    {{faculty.name}}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="select-group">
                        <label>{{ 'bulk_register.faculty_assignment.select_department' | translate }}</label>
                        <div class="custom-select" (clickOutside)="closeDepartmentDropdown()">
                            <div class="select-trigger" 
                                 (click)="toggleDepartmentDropdown()"
                                 [class.disabled]="!selectedFaculty">
                                <span>{{selectedDepartmentName || 'Select Department'}}</span>
                                <i class="fas fa-chevron-down" [class.open]="isDepartmentOpen"></i>
                            </div>
                            <div class="select-dropdown" *ngIf="isDepartmentOpen">
                                <div class="select-option" 
                                     *ngFor="let department of departments"
                                     (click)="selectDepartment(department)">
                                    {{department.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Navigation Buttons -->
            <div class="navigation-footer">
                <div class="button-container">
                    <button class="cancel-btn" (click)="cancel()">
                        {{ 'general.cancel' | translate }}
                    </button>
                    <button class="next-btn" 
                            [disabled]="!canProceedToNextStep()"
                            (click)="nextStep()">
                        {{ 'general.next' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="step-content" *ngIf="currentStep === 3">
            <!-- Step 3: Data Preview -->
            <div class="preview-container">
                <!-- File Information -->
                <div class="file-info-bar">
                    <div class="file-name">
                        <span>{{uploadedFileName || 'Institution_Educator.xlsx'}}</span>
                        <button class="remove-btn" 
                                (click)="removeFile()"
                                aria-label="Remove file and return to upload">
                            <img src="assets/images/icons/trash.svg" alt="" srcset="">
                        </button>
                    </div>
                </div>

             <div class="box">
                   <!-- Faculty Assignment Summary -->
                   <div class="assignment-summary">
                    <div class="assignment-info">
                        <h3>{{ 'bulk_register.faculty_assignment.title' | translate }}</h3>
                        <p class="section-description">
                            {{ 'bulk_register.faculty_assignment.description' | translate }}
                        </p>
                    </div>
                    <div class="assignment-status">
                        <img src="../../../assets/images/icons/check-circled-filled-success.svg" 
                             alt="Success" 
                             class="success-icon">
                    </div>
                </div>

                <!-- Data Preview Section -->
                <div class="preview-section">
                    <div class="preview-header">
                        <h3>{{ 'bulk_register.preview.title' | translate }}</h3>
                        <div class="preview-actions">
                            <p class="error-summary" *ngIf="errorFromExcel.length">
                                <ng-container *ngIf="registrationType.isSubject">
                                    {{errorFromExcel.length}} out of {{previewData.length}} subjects contain issues that need to be resolved.
                                </ng-container>
                                <ng-container *ngIf="!registrationType.isSubject">
                                    {{ 'bulk_register.preview.error_summary' | translate: { 
                                        count: errorFromExcel.length, 
                                        total: previewData.length 
                                    } }}
                                </ng-container>
                            </p>
                            <p class="success-summary" *ngIf="!errorFromExcel.length">
                                <ng-container *ngIf="registrationType.isSubject">
                                    All {{previewData.length}} subjects are valid and ready to be registered.
                                </ng-container>
                                <ng-container *ngIf="!registrationType.isSubject">
                                    All {{previewData.length}} rows are valid and require no further edits. You may proceed with user registration.
                                </ng-container>
                            </p>
                            <div class="action-group">
                                <button 
                                    class="view-errors-btn" 
                                    (click)="toggleErrorFilter()"
                                    *ngIf="errorFromExcel.length > 0">
                                    <ng-container *ngIf="registrationType.isSubject">
                                        {{ showOnlyErrors ? 'View All Subjects' : 'View Subjects with Issues' }}
                                    </ng-container>
                                    <ng-container *ngIf="!registrationType.isSubject">
                                        {{ (showOnlyErrors ? 'bulk_register.preview.view_all' : 'bulk_register.preview.view_errors') | translate }}
                                    </ng-container>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="table-container">
                        <div class="bulk-register-wrapper">
                            <table class="bulk-register-table">
                                <thead>
                                    <tr>
                                        <th class="w-5">#</th>
                                        <ng-container *ngIf="!registrationType.isSubject">
                                            <th class="w-15">{{ 'bulk_register.preview.table.first_name' | translate }}</th>
                                            <th class="w-15">{{ 'bulk_register.preview.table.last_name' | translate }}</th>
                                            <th class="w-20">{{ 'bulk_register.preview.table.email' | translate }}</th>
                                            <th class="w-15">{{ 'bulk_register.preview.table.unique_identifier' | translate }}</th>
                                            <th class="w-20" *ngIf="registrationType.isProfessor">{{ 'bulk_register.preview.table.subject' | translate }}</th>
                                        </ng-container>
                                        <ng-container *ngIf="registrationType.isSubject">
                                            <th class="w-20">Subject Name</th>
                                            <th class="w-15">Subject ID</th>
                                            <th class="w-15">Subject Type</th>
                                            <th class="w-20">Educator</th>
                                            <th class="w-20">Subject Summary</th>
                                        </ng-container>
                                        <th class="w-7 text-center">{{ 'bulk_register.preview.table.status' | translate }}</th>
                                        <th class="w-5 text-center"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let row of paginatedData">
                                        <tr [ngClass]="{'invalid-row': !row.isValid}">
                                            <td class="row-number">{{row.rowNumber}}</td>
                                            
                                            <ng-container *ngIf="!registrationType.isSubject">
                                                <td>
                                                    <input [(ngModel)]="row['First Name']" 
                                                           (ngModelChange)="validateRow(row, 'First Name')"
                                                           [class.error]="!row.isValid"/>
                                                </td>
                                                <td>
                                                    <input [(ngModel)]="row['Last Name']" 
                                                           (ngModelChange)="validateRow(row, 'Last Name')"
                                                           [class.error]="!row.isValid"/>
                                                </td>
                                                <td>
                                                    <input [(ngModel)]="row['Email']" 
                                                           (ngModelChange)="validateRow(row, 'Email')"
                                                           [class.error]="!row.isValid"/>
                                                </td>
                                                <td>
                                                    <input [(ngModel)]="row['Unique Identifier']" 
                                                           (ngModelChange)="validateRow(row, 'Unique Identifier')"
                                                           [class.error]="getFieldError(row, 'Unique Identifier')"/>
                                                </td>
                                                <td *ngIf="registrationType.isProfessor">
                                                    <div class="custom-select subjects-select" (clickOutside)="closeAllDropdowns()">
                                                        <div class="select-trigger" (click)="toggleCourseDropdown($event, row)">
                                                            <div class="selected-educators" *ngIf="row.courses?.length">
                                                                <div class="selected-course-tags">
                                                                    <ng-container *ngTemplateOutlet="courseTags; context: { $implicit: getDisplayCourses(row.courses), row: row }">
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            <span *ngIf="!row.courses?.length">{{ 'bulk_register.preview.table.select_subjects' | translate }}</span>
                                                            <i class="fas fa-chevron-down" [class.open]="isCourseDropdownOpen(row)"></i>
                                                        </div>
                                                        <div class="select-dropdown" 
                                                             *ngIf="isCourseDropdownOpen(row)"
                                                             [class.dropdown-up]="getDropdownPosition(row.rowNumber) === 'up'">
                                                            <div class="search-container">
                                                                <input type="text" 
                                                                       class="search-input" 
                                                                       [value]="courseSearchText[row.rowNumber] || ''"
                                                                       (input)="onCourseSearch($event, row)"
                                                                       (click)="$event.stopPropagation()"
                                                                       placeholder="Search by code">
                                                                <i class="fas fa-search search-icon"></i>
                                                            </div>
                                                            <div class="select-options-container">
                                                                <div class="select-option" 
                                                                     *ngFor="let course of (filteredCourses[row.rowNumber] || courses)"
                                                                     (click)="selectCourse(row, course, $event)"
                                                                     [class.selected]="isCourseSelected(row, course)">
                                                                    <span class="checkbox">
                                                                        <i class="fas fa-check" *ngIf="isCourseSelected(row, course)"></i>
                                                                    </span>
                                                                    <div class="course-info">
                                                                        <span class="course-title">{{course.title}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="no-results" *ngIf="(filteredCourses[row.rowNumber] || courses).length === 0">
                                                                    No courses found
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </ng-container>

                                            <ng-container *ngIf="registrationType.isSubject">
                                                <td>
                                                    <input [(ngModel)]="row['Subject Name']" 
                                                           (ngModelChange)="validateRow(row, 'Subject Name')"
                                                           [class.error]="getFieldError(row, 'Subject Name')"/>
                                                </td>
                                                <td>
                                                    <input [(ngModel)]="row['Subject ID']" 
                                                           (ngModelChange)="validateRow(row, 'Subject ID')"
                                                           [class.error]="getFieldError(row, 'Subject ID')"/>
                                                </td>
                                                <td>
                                                    <div class="custom-select" (clickOutside)="closeAllSubjectTypeDropdowns()">
                                                        <div class="select-trigger" (click)="toggleSubjectTypeDropdown($event, row)">
                                                            <span>{{row['Subject Type'] || 'Select Type'}}</span>
                                                            <i class="fas fa-chevron-down" [class.open]="isSubjectTypeOpen(row)"></i>
                                                        </div>
                                                        <div class="select-dropdown" 
                                                             *ngIf="isSubjectTypeOpen(row)"
                                                             [class.dropdown-up]="getDropdownPosition(row.rowNumber) === 'up'">
                                                            <div class="select-option" 
                                                                 *ngFor="let type of subjectTypes"
                                                                 (click)="selectSubjectType(type, row)">
                                                                {{type.name}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="custom-select subjects-select" (clickOutside)="closeAllEducatorDropdowns()">
                                                        <div class="select-trigger" (click)="toggleEducatorDropdown($event, row)">
                                                            <div class="selected-educators" *ngIf="row['Educator_Name']?.length">
                                                                <div class="selected-course-tags">
                                                                    <ng-container *ngTemplateOutlet="educatorTags; context: { $implicit: getDisplayEducators(row['Educator_Name']), row: row }">
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                            <span *ngIf="!row['Educator_Name']?.length">Select Educators</span>
                                                            <i class="fas fa-chevron-down" [class.open]="isEducatorDropdownOpen(row)"></i>
                                                        </div>
                                                        <div class="select-dropdown" 
                                                             *ngIf="isEducatorDropdownOpen(row)"
                                                             [class.dropdown-up]="getDropdownPosition(row.rowNumber) === 'up'">
                                                            <div class="search-container">
                                                                <input type="text" 
                                                                       class="search-input" 
                                                                       [value]="educatorSearchText[row.rowNumber] || ''"
                                                                       (input)="onEducatorSearch($event, row)"
                                                                       (click)="$event.stopPropagation()"
                                                                       placeholder="Search educators...">
                                                                <i class="fas fa-search search-icon"></i>
                                                            </div>
                                                            <div class="select-options-container">
                                                                <div class="select-option" 
                                                                     *ngFor="let educator of (filteredEducators[row.rowNumber] || educators)"
                                                                     (click)="selectEducator(row, educator, $event)"
                                                                     [class.selected]="isEducatorSelected(row, educator)">
                                                                    <span class="checkbox">
                                                                        <i class="fas fa-check" *ngIf="isEducatorSelected(row, educator)"></i>
                                                                    </span>
                                                                    <div class="educator-info">
                                                                        <span class="educator-name">{{educator.name}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="no-results" *ngIf="(filteredEducators[row.rowNumber] || educators).length === 0">
                                                                    No educators found
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input [(ngModel)]="row['Subject Summary']" 
                                                           (ngModelChange)="validateRow(row, 'Subject Summary')"
                                                           [class.error]="getFieldError(row, 'Subject Summary')"/>
                                                </td>
                                            </ng-container>

                                            <td class="status-column">
                                                <div class="status-container">
                                                    <img [src]="getStatusIcon(row.status)" 
                                                         [alt]="row.status" 
                                                         class="status-icon"
                                                         [class]="getStatusClass(row.status)">
                                                </div>
                                            </td>
                                            <td class="actions-column">
                                                <button 
                                                    class="delete-button"
                                                    (click)="confirmDeleteRow(paginatedData.indexOf(row))"
                                                    aria-label="Delete row">
                                                    <img src="../../../assets/images/icons/trash.svg" 
                                                         alt="Delete"
                                                         class="delete-icon">
                                                </button>
                                            </td>
                                        </tr>
                                        <!-- Error Messages -->
                                        <tr *ngIf="!row.isValid" class="error-row">
                                            <td [attr.colspan]="registrationType.isSubject ? 8 : (registrationType.isProfessor ? 8 : 7)">
                                                <div class="error-messages">
                                                    <ng-container *ngIf="registrationType.isSubject">
                                                        <div class="error-message" 
                                                             *ngFor="let error of getFieldErrors(row)"
                                                             [class.warning]="isDuplicateError(error)">
                                                            {{error}}
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="!registrationType.isSubject">
                                                        <ng-container *ngIf="getGroupedErrors(row).errors.length > 0">
                                                            <div class="error-message" 
                                                                 *ngFor="let error of getGroupedErrors(row).errors"
                                                                 data-error-type="error">
                                                                {{error}}
                                                            </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="getGroupedErrors(row).warnings.length > 0">
                                                            <div class="error-message warning" 
                                                                 *ngFor="let warning of getGroupedErrors(row).warnings"
                                                                 data-error-type="warning">
                                                                {{warning}}
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button 
                        class="delete-errors-btn" 
                        (click)="confirmDeleteAllErrorRows()"
                        *ngIf="this.showOnlyErrors">
                        {{ 'bulk_register.preview.delete_errors' | translate }}
                    </button>
                    <!-- Replace the existing pagination controls and buttons with this -->
                    <div class="table-footer">
                        <div class="pagination-section">
                            <div class="items-per-page">
                                <div class="custom-select" (clickOutside)="isPaginationDropdownOpen = false">
                                    <div class="select-trigger" (click)="togglePaginationDropdown($event)">
                                        <span>Show: {{pageSize}} items</span>
                                        <i class="fas fa-chevron-down" [class.open]="isPaginationDropdownOpen"></i>
                                    </div>
                                    <div class="select-dropdown" *ngIf="isPaginationDropdownOpen">
                                        <div class="select-option" 
                                             *ngFor="let size of pageSizeOptions"
                                             (click)="onPageSizeChange(size)">
                                            {{size}} items
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="page-info">
                                {{(currentPage - 1) * pageSize + 1}}-{{currentPage * pageSize}} of {{previewData.length}}
                            </div>
                            <div class="navigation-arrows">
                                <button class="nav-btn" (click)="previousPage()" [disabled]="currentPage === 1">
                                    <i class="fas fa-chevron-left"></i>
                                </button>
                                <button class="nav-btn" (click)="nextPage()" [disabled]="currentPage === totalPages">
                                    <i class="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        </div>
                        <div class="action-buttons">
                            <button class="cancel-btn" (click)="cancel()">
                                {{ 'general.cancel' | translate }}
                            </button>
                            <button class="finish-btn" 
                                    (click)="uploadToServer()">
                                {{ 'general.register' | translate }}
                            </button>
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
             </div>
            </div>
        </div>

    </div>
</ng-container>

<!-- Add this at the end of your template, after all other content -->
<ng-template #educatorTags let-displayData let-row="row">
    <ng-container *ngFor="let educator of displayData.shown; let i = index">
        <span class="course-tag">
            <span>{{educator}}</span>
            <button class="remove-educator" 
                    (click)="removeEducator(row, findEducatorByName(row, educator), $event)">×</button>
        </span>
    </ng-container>
    <span class="more-tag" *ngIf="displayData.remaining > 0">
        +{{displayData.remaining}} more
    </span>
</ng-template>

<ng-template #courseTags let-displayData let-row="row">
    <ng-container *ngFor="let course of displayData.shown; let i = index">
        <span class="course-tag">
            <span>{{course.title}}</span>
            <button class="remove-educator" 
                    (click)="removeCourse(row, course, $event)">×</button>
        </span>
    </ng-container>
    <span class="more-tag" *ngIf="displayData.remaining > 0">
        +{{displayData.remaining}} more
    </span>
</ng-template>