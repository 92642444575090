<div class="custom-slider">
  <button class="reset-button" (click)="resetSlider()">Reset</button>
  <div class="slider-wrapper" (click)="onSliderClick($event)">
    <div class="slider-track" #sliderTrack>
      <div class="slider-range"></div>
      <div class="slider-range"></div>
      <div class="slider-range"></div>
    </div>

    <div *ngFor="let handle of handles; let i = index"
         class="slider-handle"
         [ngStyle]="{
           'left': handle.value + '%',
           'background-color': getHandleColor(i),
           'height': handleHeight,
           'width': handleWidth
         }"
         [ngClass]="{
           'active': activeHandle === i,
           'at-hard-limit': isHandleAtHardLimit(handle)
         }"
         (mousedown)="startDragging($event, i)">
      <div class="handle-value" *ngIf="showHandleValues">{{ handle.value }}%</div>
    </div>
  </div>

  <div class="percentage-markers" *ngIf="showPercentageMarkers">
    <ng-container *ngFor="let marker of [0,10,20,30,40,50,60,70,80,90,100]">
      <span (click)="onPercentageClick(marker)"
            [ngClass]="getHandleColorClass(marker)"
            [class.out-of-hard-limit]="isOutOfHardLimit(marker)">
        {{marker}}%
      </span>
    </ng-container>
  </div>
</div>
