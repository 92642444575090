import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-student-register',
   templateUrl: './student-register.component.html',
   styleUrls: ['./student-register.component.scss'],
})
export class StudentRegisterComponent implements OnInit {
   constructor() {}

   ngOnInit(): void {
   }

}
