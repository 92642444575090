<div class="confirmation-dialog" [ngClass]="data.type">
  <!-- Header -->
  <div class="dialog-header">
    <h2 class="dialog-title" *ngIf="data.type !== 'success'">{{ data.title }}</h2>
    <button class="close-btn" (click)="onCancel()" aria-label="Close dialog">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 5L5 15M5 5L15 15" stroke="currentColor" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>

  <!-- Content -->
  <div class="dialog-content-wrapper">
    <!-- Success Icon (only for success type) -->
    <div class="dialog-image" *ngIf="data.type === 'success' && data.imageUrl">
      <img [src]="data.imageUrl" 
           [alt]="data.imageAlt || ''" 
           [style.width]="data.imageWidth"
           [style.height]="data.imageHeight">
    </div>

    <!-- Title (only for success type) -->
    <h2 class="dialog-title" *ngIf="data.type === 'success'">{{ data.title }}</h2>

    <!-- Message -->
    <div class="dialog-content">
      <p [innerHTML]="getInterpolatedMessage()"></p>
    </div>

    <!-- Actions -->
    <div class="dialog-actions">
      <div class="button-container">
        <!-- For non-success modals -->
        <ng-container *ngIf="data.type !== 'success'">
          <button *ngIf="data.showCancel" 
                  class="cancel-btn" 
                  (click)="onCancel()">
            {{ data.cancelText }}
          </button>
          <button class="confirm-btn" 
                  (click)="onConfirm()">
            {{ data.confirmText }}
          </button>
        </ng-container>
        
        <!-- For success modals -->
        <button *ngIf="data.type === 'success'"
                class="cancel-btn" 
                (click)="onCancel()">
          {{ data.confirmText }}
        </button>
      </div>
    </div>
  </div>
</div> 