import { ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, SimpleChanges, ViewChild, ElementRef, Renderer2, OnDestroy, HostListener } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from "src/app/super-admin/super-admin.service";
import { LanguageService } from "../report-v2/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSelectChange } from "@angular/material/select";
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Handle, SliderRange } from '../../shared/components/custom-slider/custom-slider.component';

@Component({
    selector: 'app-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.scss']
})

export class AdminSettingsComponent implements OnInit, OnDestroy {
    initialFormValues: any;
    languages = [
      {
         text: 'English',
         value: 'en',
      },
      {
         text: 'Albanian',
         value: 'sq',
      },
      {
         text: 'German',
         value: 'de',
      },
      {
         text: 'Italian',
         value: 'it',
      },
      {
         text: 'French',
         value: 'fr',
      },
      {
         text: 'Spanish',
         value: 'es',
      },
      {
         text: 'Greek',
         value: 'el',
      },
      {
         text: 'Czech',
         value: 'cs',
      },
      {
         text: 'Turkish',
         value: 'tr',
      },
      {
         text: 'Slovak',
         value: 'sk',
      },
      {
         text: 'Lithuanian',
         value: 'lt',
      },
      {
         text: 'Latvian',
         value: 'lv',
      },
      {
         text: 'Polish',
         value: 'pl',
      },
      {
         text: 'Serbian',
         value: 'sr',
      },
      {
         text: 'Macedonian',
         value: 'mk',
      },
      {
         text: 'Portuguese',
         value: 'pt',
      },
      {
         text: 'Dutch',
         value: 'nl',
      },
      {
         text: 'Russian',
         value: 'ru',
      },
      {
         text: 'Bulgarian',
         value: 'bg',
      },
      {
         text: 'Hungarian',
         value: 'hu',
      },
      {
         text: 'Romanian',
         value: 'ro',
      },
      {
         text: 'Slovenian',
         value: 'sl',
      },
      {
         text: 'Swedish',
         value: 'sv',
      },
      {
         text: 'Finnish',
         value: 'fi',
      },
      {
         text: 'Croatian',
         value: 'hr',
      },
      {
         text: 'Bosnian',
         value: 'bs',
      },
      {
         text: 'Norwegian',
         value: 'no',
      },
      {
         text: 'Danish',
         value: 'da'
      },
      {
         text: 'Estonian',
         value: 'et'
      }
   ];
   translatedLanguageEnabled = new FormControl(false); // Using FormControl for the toggle
   selectedTranslatedLanguages = new FormControl([], [Validators.maxLength(3)]);
   allChipOptions: { value: string, title: string }[] = [
    { value: "PERSON", title: "Person Name" },
    { value: "PHONE_NUMBER", title: "Phone Number" },
    { value: "IP_ADDRESS", title: "IP Address" },
    { value: "LOCATION", title: "Location" },
    { value: "IBAN_CODE", title: "IBAN Code" },
    { value: "NRP", title: "nrp" },
    { value: "DATE_TIME", title: "Date and Time" },
    { value: "URL", title: "URL" },
    { value: "MEDICAL_LICENSE", title: "Medical License" },
    // { value: "BTC_ADDRESS", title: "Bitcoin Address" },
    { value: "UUID", title: "UUID" },
    { value: "EMAIL_ADDRESS", title: "Email Address" },
    { value: "CREDIT_CARD", title: "Credit Card Number" }
  ];
  defaultChipOptions = [
    { value: "EMAIL_ADDRESS", title: "Email Address" },
    { value: "CREDIT_CARD", title: "Credit Card Number" },
    { value: "NRP", title: "nrp" }
  ];
   archive: boolean = false;
   isFormChanged = false;
   selectedContentInfo: { text: string } | null = null;
   selectedContentValue: string = '';
   admin_settings = this.fb.group({
    documentTranslatedLanguage: this.selectedTranslatedLanguages,
    aiDetection: [false],
    submissionPreCheck: [false],
    contextualSimilarity: [false],
    enableThresholds: [false],
    exactMatchThreshold: [80, [Validators.max(100)]],
    alteredTextThreshold: [40, [Validators.max(100)]],
    contextualSimilaritiesThreshold: [80, [Validators.max(100), Validators.min(50)]],
    archive: [false],
    contentAccess: ["closed"],
    institutionsContentAccess: [],
    personalIdentifiableInformation: [false],
    canRegisterStudents: [false],
    personalIdentifiableInformationInfo: this.defaultChipOptions || []
  });
  chipInputControl = new FormControl();
  chips: string[] = [];
  filteredOptions: Observable<string[]>;
  showChipInput = false;
  filteredChipOptions;
  @ViewChild('chipInput') chipInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('addMoreButton') addMoreButton: ElementRef;

  private clickListener: () => void;

  handles = [
    { value: 60, min: 40, max: 90 },  // Altered Text
    { value: 80, min: 60, max: 90 } // Exact Match
  ];

  // First slider configuration
  similarityHandles: Handle[] = [
    { value: 60, min: 40, max: 90 },
    { value: 80, min: 60, max: 90 }
  ];
  similarityDefaultValues = [];

  // Second slider configuration for contextual similarity
  translationHandles: Handle[] = [
    { value: 50, min: 0, max: 50, hardMinimum: 50 },
    { value: 80, min: 50, max: 95 }
  ];
  translationDefaultValues = [];

  similarityRangeColors: SliderRange[] = [
    { color: '#BCBCBC', label: 'Below Threshold' },
    { color: '#7ABDFF', label: 'Altered Text' },
    { color: '#F386AB', label: 'Exact Match' }
  ];

  translationRangeColors: SliderRange[] = [
    { color: '#D9D9D9', label: '' },
    { color: '#BCBCBC', label: '' },
    { color: '#FED27C', label: 'Contextual Similarity' }
  ];

  isDragging = false;
  activeHandle: number | null = null;

    constructor(
      private fb: FormBuilder,
      private toastrService: ToastrService,
      private superAdminService: SuperAdminService,
      private languageService: LanguageService,
      private translate: TranslateService,
      private spinner: NgxSpinnerService,
      private cdr: ChangeDetectorRef,
      private renderer: Renderer2
    ) { }

    ngOnInit(): void {
      const storedLanguage = localStorage.getItem('websiteLanguage');
      const language = storedLanguage ? storedLanguage : 'en';

      this.translate.use(language);
      this.languageService.selectedLanguage$.subscribe((language: string) => {
        this.translate.use(language);
      });
      this.languages.sort((a, b) => a.text.localeCompare(b.text));
      this.admin_settings = this.fb.group({
        documentTranslatedLanguage: this.selectedTranslatedLanguages,
        aiDetection: [false],
        submissionPreCheck: [false],
        contextualSimilarity: [false],
        enableThresholds: [false],
        exactMatchThreshold: [80, [Validators.max(100)]],
        alteredTextThreshold: [40, [Validators.max(100)]],
        contextualSimilaritiesThreshold: [80, [Validators.max(100), Validators.min(50)]],
        archive: [false],
        contentAccess: ["closed"],
        institutionsContentAccess: [],
        personalIdentifiableInformation: [false],
        canRegisterStudents: [false],
        personalIdentifiableInformationInfo: this.defaultChipOptions || [],
      });


       // Retrieve data from local storage
       const storedData = localStorage.getItem('user');
       if (storedData) {
          const institutionData = JSON.parse(storedData);

          if (institutionData && institutionData.Institution && institutionData.Institution.settings) {
             const settings = institutionData.Institution.settings;

             // Prefill the form with retrieved data
             this.admin_settings.patchValue({
                documentTranslatedLanguage: settings.translatedLanguage || [],
                aiDetection: !!settings.ai,
                submissionPreCheck: !!settings.submission_pre_check,
                contextualSimilarity: !!settings.contextualSimilarity,
                enableThresholds: !!settings.thresholds,
                exactMatchThreshold: settings.thresholds?.exact_match || 0,
                alteredTextThreshold: settings.thresholds?.altered_text || 0,
                contextualSimilaritiesThreshold: settings.thresholds?.contextual_similarity || 80,
                archive: !!settings.archive,
                contentAccess: settings.contentAccess,
                institutionsContentAccess: settings.institutionsContentAccess,
                personalIdentifiableInformation: settings.personalIdentifiableInformation,
                canRegisterStudents: settings.canRegisterStudents,
                personalIdentifiableInformationInfo: settings.personalIdentifiableInformationInfo
             });

             // Enable the translatedLanguage toggle if there are selected languages
             this.translatedLanguageEnabled.setValue(settings.translatedLanguage && settings.translatedLanguage.length > 0);
             this.selectedTranslatedLanguages.setValue(settings.translatedLanguage || []);

          }
       }

       // Store initial form values
       this.initialFormValues = this.admin_settings.getRawValue();

       this.admin_settings.valueChanges.subscribe(() => {
          this.checkIfFormChanged();
       });

       this.translatedLanguageEnabled.valueChanges.subscribe((enabled) => {
          if (!enabled) {
             this.selectedTranslatedLanguages.setValue([]);
          }
       });

       const initialContentAccess = this.admin_settings.controls['contentAccess'].value;
       this.selectedContentValue = initialContentAccess;

       this.admin_settings.controls['contentAccess'].valueChanges.subscribe((value) => {
        this.selectedContentValue = value;
      });
      this.fetchChips()
      this.filterChips();

      this.admin_settings.get('personalIdentifiableInformation').valueChanges.subscribe((enabled) => {
        const currentDefaultChips = [
          { value: "EMAIL_ADDRESS", title: "Email Address" },
          { value: "CREDIT_CARD", title: "Credit Card Number" },
          { value: "NRP", title: "nrp" }
        ];

        if (enabled) {
          this.admin_settings.get('personalIdentifiableInformationInfo').setValue(currentDefaultChips);
        } else {
          this.admin_settings.get('personalIdentifiableInformationInfo').setValue([]);
        }
        // this.updateFilteredChipOptions();
      });

      // When loading stored settings
      if (storedData) {
        const institutionData = JSON.parse(storedData);
        if (institutionData?.Institution?.settings?.thresholds) {
          const thresholds = institutionData.Institution.settings.thresholds;

          // Update slider handles with stored values
          this.similarityHandles = [
            { value: thresholds.altered_text || 60, min: 40, max: 90 },
            { value: thresholds.exact_match || 80, min: 60, max: 90 }
          ];

          this.similarityDefaultValues = [thresholds.altered_text || 60, thresholds.exact_match || 80];
          this.translationDefaultValues = [50, thresholds.contextual_similarity || 80];

          if (institutionData.Institution.settings.contextualSimilarity) {
            this.translationHandles = [
              { value: 50, min: 0, max: 50, hardMinimum: 50 },
              { value: thresholds.contextual_similarity || 80, min: 50, max: 95 }
            ];
          }
        }
      }
    }

    ngDoCheck() {
      if (localStorage.getItem('websiteLanguage') !== this.translate.currentLang) {
        this.translate.use(localStorage.getItem('websiteLanguage'));
        this.cdr.detectChanges();
      }
    }

    checkIfFormChanged() {
      const currentFormValues = this.admin_settings.getRawValue();

      // Compare current values with initial values to determine if the form has changed
      this.isFormChanged = JSON.stringify(currentFormValues) !== JSON.stringify(this.initialFormValues);
    }

    isSelected(language: string): boolean {
      return this.selectedTranslatedLanguages.value.includes(language);
   }

   get form() {
    return this.admin_settings.controls;
  }

  selectTranslatedLanguage(event) {
    this.selectedTranslatedLanguages = event.value;
    this.form.documentTranslatedLanguage.setValue(this.selectedTranslatedLanguages);
  }

  archiveDocument() {
    const currentValue = this.admin_settings.controls['archive'].value;
    this.admin_settings.controls['archive'].setValue(!currentValue);
 }

   async submitSettings() {

      await Swal.fire({
         title: this.translate.instant('new_entries.do_you_want_to_save_current_settings'),
         icon: 'info',
         showCancelButton: true,
         confirmButtonText: this.translate.instant('general.yes'),
         cancelButtonText: this.translate.instant('general.no'),
         confirmButtonColor: '#4dbd74',
         cancelButtonColor: '#f86c6b',
      }).then((result) => {
         if (result.isConfirmed) {

            if (this.admin_settings.valid) {
               const formValues = this.admin_settings.value;
               console.log(formValues.documentTranslatedLanguage, formValues?.documentTranslatedLanguage);

               const settings = {
                  ai: formValues.aiDetection ? 1 : 0,
                  archive: formValues.archive ? 1 : 0,
                  originalLanguage: formValues.documentTranslatedLanguage[0] || 'en',
                  translatedLanguage: formValues?.documentTranslatedLanguage.length ? formValues.documentTranslatedLanguage : [],
                  submission_pre_check: formValues.submissionPreCheck ? 1 : 0,
                  contextualSimilarity: formValues.contextualSimilarity || 0,
                  enableThresholds: formValues.enableThresholds || 0,
                  thresholds: {
                     exact_match: formValues.exactMatchThreshold || 0,
                     altered_text: formValues.alteredTextThreshold || 0,
                     contextual_similarity: formValues.contextualSimilaritiesThreshold || 0
                  },
                  contentAccess:formValues.contentAccess,
                  institutionsContentAccess: formValues.institutionsContentAccess,
                  personalIdentifiableInformation: formValues.personalIdentifiableInformation,
                  canRegisterStudents: formValues.canRegisterStudents,
                  personalIdentifiableInformationInfo: formValues.personalIdentifiableInformationInfo.map(item => item.value)
               };

               this.spinner.show();

               this.superAdminService.createInstitution(
                  settings,
                  undefined,
                  undefined,
                  undefined,
                  true
               ).subscribe(
                  response => {
                     this.toastrService.success(this.translate.instant('new_entries.institution_created_successfully'));

                     // Update local storage with the new settings
                     const storedData = localStorage.getItem('user');
                     if (storedData) {
                        const institutionData = JSON.parse(storedData);

                        // Update the settings in the local storage
                        institutionData.Institution.settings = {
                           ...institutionData.Institution.settings,
                           ...settings,
                        };

                        console.log('institutionData', institutionData)

                        localStorage.setItem('user', JSON.stringify(institutionData));
                     }

                     // Update the initial form values
                     this.initialFormValues = this.admin_settings.getRawValue();
                     this.isFormChanged = false;
                     this.spinner.hide();
                  },
                  error => {
                     this.spinner.hide();
                     this.toastrService.error(this.translate.instant('new_entries.institution_creation_error'));
                     console.error(error);
                  }
               );
            } else {
               console.log('Form is invalid, please correct the errors.');
            }
         } else if (result.isDenied) {
            this.toastrService.info(this.translate.instant('new_entries.settings_not_saved'));
         }
      });
   }

 cancel() {
    this.admin_settings.reset();
    this.admin_settings.setValue(this.initialFormValues);
    this.isFormChanged = false;

    // Fetch and set the chips from user settings
    this.fetchChips();
    this.filterChips();

    // Reset the translatedLanguageEnabled control
    this.translatedLanguageEnabled.setValue(this.initialFormValues.documentTranslatedLanguage.length > 0);
 }

 preventInput(event: KeyboardEvent) {
  event.preventDefault();
 }

  addChip(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Prevent manual input
    if (!this.defaultChipOptions.some(option => option.value === value.trim())) {
      return;
  }

    // Add our chip
    if ((value || '').trim()) {
        this.chips.push(value.trim());
    }

    // Reset the input value
    if (input) {
        input.value = '';
    }

    this.chipInputControl.setValue(null);

    // Hide the input after adding a chip
    this.showChipInput = false;
  }

  removeChip(chip: any): void {
    const currentChips = this.admin_settings.get('personalIdentifiableInformationInfo').value;
    const updatedChips = currentChips.filter(c => c.value !== chip.value);
    this.admin_settings.get('personalIdentifiableInformationInfo').setValue(updatedChips);
    this.updateFilteredChipOptions();
  }

  selectChip(event: any): void {
    this.chips.push(event.option.viewValue);
    this.chipInputControl.setValue(null);
  }

  toggleChipInput() {
    this.showChipInput = !this.showChipInput;
    if (this.showChipInput) {
      setTimeout(() => {
        this.chipInput.nativeElement.focus();
        this.openAutocomplete();
      });
    }
  }

  openAutocomplete() {
    this.chipInputControl.setValue('');

  }

  addChipFromAutocomplete(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    const currentChips = this.admin_settings.get('personalIdentifiableInformationInfo').value;
    if (value && !currentChips.some(chip => chip.value === value.value)) {
      this.admin_settings.get('personalIdentifiableInformationInfo').setValue([...currentChips, value]);
      this.chipInputControl.setValue('');
    }
    this.showChipInput = false;
    this.updateFilteredChipOptions();
  }

  fetchChips() {
    const storedData = localStorage.getItem('user');
    if (storedData) {
      const institutionData = JSON.parse(storedData);
      if (institutionData && institutionData.Institution && institutionData.Institution.settings) {
        const settings = institutionData.Institution.settings;
        const storedChips = settings.personalIdentifiableInformationInfo || [];

        // Map stored chip values to full chip objects
        const selectedChips = storedChips.map(value => {
          const matchingOption = this.allChipOptions.find(option => option.value === value);
          return matchingOption || { value, title: value };
        });

        // Set the selected chips in the form
        this.admin_settings.get('personalIdentifiableInformationInfo').setValue(selectedChips);
      }
    }
    this.updateFilteredChipOptions();
  }

  addChipFromDropdown(event: MatSelectChange) {
    const value = event.value;
    if (value && !this.chips.includes(value)) {
      this.chips.push(value);
      this.chipInputControl.setValue(null);
    }
    this.filterChips();
    this.showChipInput = false;
  }

  private updateFilteredChipOptions() {
    this.filteredChipOptions = this.chipInputControl.valueChanges.pipe(
      startWith(''),
      map((value: string | null) => this._filter(value || ''))
    );
  }

  private _filter(value: string): { value: string, title: string }[] {
    const filterValue = value.toLowerCase();
    const currentChips = this.admin_settings.get('personalIdentifiableInformationInfo').value;
    const currentChipValues = currentChips.map(chip => chip.value);

    return this.allChipOptions
      .filter(option => !currentChipValues.includes(option.value))
      .filter(option =>
        option.value.toLowerCase().includes(filterValue) ||
        option.title.toLowerCase().includes(filterValue)
      );
  }

  private filterChips() {
    const currentChips = this.admin_settings.get('personalIdentifiableInformationInfo').value;
    const currentChipValues = currentChips.map(chip => chip.value);
    console.log(currentChipValues, 'currentChipValues');

    this.filteredChipOptions = this.allChipOptions.filter(option => !currentChipValues.includes(option.value));
    console.log(this.filteredChipOptions, 'filteredChipOptions');


    // Return  allChipOptions that are not in currentChipValues
    return this.allChipOptions.filter(option => !currentChipValues.includes(option.value));

  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
  }

  private updateTrackColors() {
    const track = document.querySelector('.slider-track') as HTMLElement;
    if (!track) return;

    requestAnimationFrame(() => {
      // Ensure we have valid values
      const alteredStart = this.handles[0]?.value ?? 0;
      const exactStart = this.handles[1]?.value ?? 100;

      // Update the CSS variables
      track.style.setProperty('--altered-start', `${alteredStart}%`);
      track.style.setProperty('--exact-start', `${exactStart}%`);
    });
 }

 private updateHandleConstraints() {
  // Update Altered Text constraints
  this.handles[0].max = this.handles[1].value - 1;

  // Update Exact Match constraints
  this.handles[1].min = this.handles[0].value + 1;
}

  // Add these methods to handle slider changes
  onSimilarityHandleChange(handles: Handle[]) {
    this.similarityHandles = [...handles];

    // Update form controls and allow event emission
    this.admin_settings.patchValue({
      alteredTextThreshold: handles[0].value,
      exactMatchThreshold: handles[1].value
    });

    // Update the CSS variables for the ranges
    const sliderElement = document.querySelector('.custom-slider') as HTMLElement;
    if (sliderElement) {
      sliderElement.style.setProperty('--altered-start', `${handles[0].value}%`);
      sliderElement.style.setProperty('--exact-start', `${handles[1].value}%`);
    }
  }

  onTranslationHandleChange(handles: Handle[]) {
    this.translationHandles = [...handles];

    // Update form control and allow event emission
    this.admin_settings.patchValue({
      contextualSimilaritiesThreshold: handles[1].value
    });

    // Update the CSS variable for the range
    const sliderElement = document.querySelector('.custom-slider') as HTMLElement;
    if (sliderElement) {
      sliderElement.style.setProperty('--contextual-start', `${handles[1].value}%`);
    }
  }

  // Update the validation method
  private validateThresholds(): boolean {
    if (this.admin_settings.get('enableThresholds').value) {
      const alteredThreshold = this.admin_settings.get('alteredTextThreshold').value;
      const exactThreshold = this.admin_settings.get('exactMatchThreshold').value;
      const contextualThreshold = this.admin_settings.get('contextualSimilaritiesThreshold').value;

      // Validate that values are within expected ranges
      if (alteredThreshold < 40 || alteredThreshold > 90 ||
          exactThreshold < 60 || exactThreshold > 90 ||
          (this.admin_settings.get('contextualSimilarity').value &&
           (contextualThreshold < 50 || contextualThreshold > 100))) {
        this.toastrService.error('Invalid threshold values');
        return false;
      }

      // Validate that altered threshold is less than exact threshold
      if (alteredThreshold >= exactThreshold) {
        this.toastrService.error('Altered text threshold must be less than exact match threshold');
        return false;
      }
    }
    return true;
  }

  // Add these methods for drag handling
  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging && this.activeHandle !== null) {
      const sliderRect = (event.target as HTMLElement)
        .closest('.slider-wrapper')
        ?.getBoundingClientRect();

      if (sliderRect) {
        const percentage = Math.min(Math.max(
          ((event.clientX - sliderRect.left) / sliderRect.width) * 100,
          0
        ), 100);

        const roundedPercentage = Math.round(percentage);
        // const handles = this.activeHandle === 0 ? this.similarityHandles : this.translationHandles;

        switch(this.activeHandle) {
          case 0: // Altered Text
            const maxAltered = Math.min(this.handles[1].value - 1, this.handles[0].max);
            this.handles[0].value = Math.min(Math.max(roundedPercentage, this.handles[0].min), maxAltered);
            break;

          case 1: // Exact Match
            const minExact = Math.max(this.handles[0].value + 1, this.handles[1].min);
            this.handles[1].value = Math.min(Math.max(roundedPercentage, minExact), this.handles[1].max);
            break;
        }

        this.updateHandleConstraints();
        this.updateTrackColors();
      }
    }
  }

  @HostListener('window:mouseup')
  onMouseUp() {
    this.isDragging = false;
    this.activeHandle = null;
  }

  startDragging(event: MouseEvent, index: number) {
    this.isDragging = true;
    this.activeHandle = index;
    event.preventDefault();
  }

  openLink(url: string): void {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

}
