import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
/**
 * Terms and conditions component used to show user the terms and conditions of our application.
 */
@Component({
   selector: 'app-terms-and-conditions',
   templateUrl: './terms-and-conditions.component.html',
   styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
   constructor(
      private dialogRef: MatDialogRef<TermsAndConditionsComponent>
   ) {}

   ngOnInit(): void {}

   /**
    * Closes the terms and conditions modal
    */
   closeDialog(): void {
      this.dialogRef.close();
   }
}
