<section class="access_content">
   <section class="cards">
      <div class="card">
         {{ 'new_entries.requests_for_access_to_documents' | translate }}
      </div>
   </section>
   <section class="body">
    <section class="filters" *ngIf="filteredRequests?.length">
      <mat-form-field>
        <mat-select [(value)]="selectedRequestFilter" (selectionChange)="filterRequests()" panelClass="filter">
          <mat-option *ngFor="let req_type of request_filters" [value]="req_type.value">{{ req_type.viewValue }}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    <!-- Add zero-state section -->
    <div class="zero-state" *ngIf="!filteredRequests?.length">
      <h5>{{ 'app.you_dont_have_any_requests' | translate }}</h5>
    </div>

    <!-- Show table only when there are requests -->
    <section class="table" *ngIf="filteredRequests?.length">
      <table>
        <tr>
          <th>{{ 'new_entries.requester_name' | translate }}</th>
          <th>{{ 'new_entries.institution_name' | translate }}</th>
          <th>{{ 'new_entries.document_title' | translate }}</th>
          <th>{{ 'new_entries.request_date' | translate }}</th>
          <th>{{ 'new_entries.audit_log' | translate }}</th>
          <th>{{ 'new_entries.status' | translate }}</th>
        </tr>
        <tr *ngFor="let request of filteredRequests">
          <td>{{ request.user.name }}</td>
          <td>{{ request.user.Institution.name  }}</td>
          <td>{{ request.submissions.title  }}</td>
          <td>{{ request.createdAt | date: 'dd/MM/yyyy'  }}</td>
          <td>
            <button (click)="openViewLogs(request,request.institutionId != user.institutionId)">{{ 'new_entries.view_logs' | translate }}</button>
          </td>
          <td>
            <button *ngIf="request.institutionId == user.institutionId" class="other_institution">
              <mat-slide-toggle (change)="changeAccess(request.id,request.status)" (ngModel)="request.status"  [checked]="request.status"  color="primary">{{ (request.status ? 'new_entries.approved' : 'new_entries.revoked') | translate }}</mat-slide-toggle>
            </button>
            <button *ngIf="!request.status && request.institutionId != user.institutionId" class="your_institution revoked">{{ 'new_entries.revoked' | translate }}</button>
            <button *ngIf="request.status && request.institutionId != user.institutionId" class="your_institution approved">{{ 'new_entries.approved' | translate }}</button>
          </td>
        </tr>
      </table>
    </section>
   </section>
</section>
