// *** Angular
import { Component, Inject, OnInit } from '@angular/core';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

// *** Services
import { FacultyService } from 'src/app/services/faculty.service';

// *** Actions
import * as facultyActions from '../../state/faculty/action/faculty.actions';

// *** Packages
import swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
   selector: 'app-edit-faculty',
   templateUrl: './edit-faculty.component.html',
   styleUrls: ['./edit-faculty.component.scss'],
})
export class EditFacultyComponent implements OnInit {
  currentLanguage = localStorage.getItem('websiteLanguage');
   /**
    * Variable used to store facultyId that we passe from units-faculties
    */
   facultyId;

   /**
    * Variable used to store faculty object that we retrieve from API.
    */
   faculty;

   /**
    * Variable used to store FormGroup values that are being edited on modal.
    */
   facultyEdit: FormGroup;

   constructor(
      public dialogRef: MatDialogRef<EditFacultyComponent>,
      public dialog: MatDialog,
      private facultyService: FacultyService,
      private store: Store,
      private fb: FormBuilder,
      private languageService: LanguageService,
      public translate: TranslateService,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   ngOnInit(): void {
      this.facultyEdit = this.fb.group({
         facultyName: new FormControl('', Validators.required),
         facultyDescription: new FormControl('', Validators.required),
      });
      this.facultyId = this.data.facultyId;
      this.facultyService
         .faculty(this.facultyId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.faculty = data.faculty;
               this.facultyEdit = this.fb.group({
                  facultyName: new FormControl(this.faculty.name, [
                     Validators.required,
                     Validators.minLength(3),
                  ]),
                  facultyDescription: new FormControl(
                     this.faculty.description,
                     [Validators.required, Validators.minLength(3)]
                  ),
               });
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.facultyEdit.controls;
   }
   /**
    * Method is used to call editFaculty service that will send updated fields of faculty to API.
    */
   async update() {
      const result = await swal.fire({
         title: 'Confirm update of faculty',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.confirm'),
         cancelButtonText: this.translate.instant('report.cancel'),
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            facultyActions.editFacultyDetails({
               facultyId: this.facultyId,
               facultyName: this.form.facultyName.value,
               facultyDescription: this.form.facultyDescription.value,
               page: this.data.page,
               name: this.data.name,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }
   /**
    * Method is used to call remove faculty service.
    */
   async delete() {
      const result = await swal.fire({
         title: this.translate.instant('new_entries.delete_faculty_modal_title'),
         text: this.translate.instant('new_entries.delete_modal_text'),
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#d33',
         cancelButtonColor: '#b5adad',
         confirmButtonText: 'Delete',
         cancelButtonText: this.translate.instant('report.cancel'),
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            facultyActions.removeFaculty({
               facultyId: this.facultyId,
               page: this.data.page,
               name: this.data.name,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }
}
