import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmationDialogData {
  title: string;
  message: string;
  confirmText: string;
  cancelText?: string;
  showCancel?: boolean;
  imageUrl?: string;
  imageAlt?: string;
  imageWidth?: string;
  imageHeight?: string;
  type?: 'success' | 'warning' | 'danger' | 'info';
  messageParams?: { [key: string]: string };
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {
    this.data = {
      ...{
        showCancel: true,
        cancelText: 'Cancel',
        type: 'info'
      },
      ...data
    };
  }

  getInterpolatedMessage(): string {
    if (!this.data.messageParams) return this.data.message;
    
    return this.data.message.replace(/\{\{(\w+)\}\}/g, (match, key) => {
      const value = this.data.messageParams?.[key];
      return value ? `<strong>${value}</strong>` : match;
    });
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
} 