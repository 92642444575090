import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiLoggerService, ApiLog } from './api-logger.service';

@Injectable()
export class ApiLoggingInterceptor implements HttpInterceptor {

  constructor(private apiLogger: ApiLoggerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.shouldLogRequest(request.url)) {
      return next.handle(request).pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
              const log: ApiLog = {
                url: request.url,
                method: request.method,
                requestBody: request.body,
                response: event.body,
                timestamp: new Date()
              };
              // this.apiLogger.logApi(log);
            }
          },
          error => {
            const log: ApiLog = {
              url: request.url,
              method: request.method,
              requestBody: request.body,
              response: error,
              timestamp: new Date()
            };
            // this.apiLogger.logApi(log);
          }
        )
      );
    }
    return next.handle(request);
  }

  private shouldLogRequest(url: string): boolean {
    const excludedPaths = ['/assets/'];
    return !excludedPaths.some(path => url.includes(path));
  }
}