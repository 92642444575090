// *** Angular
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
   MatDialog,
   MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { DepartmentService } from 'src/app/services/department.service';
import {
   FormBuilder,
   FormControl,
   FormGroup,
   Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';

// *** Packages
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

// *** Actions
import * as professorActions from '../../state/professor/action/professor.actions';
import * as facultyActions from '../../state/faculty/action/faculty.actions';

// *** Selectors
import { getInstitutionFacultiesStateDetails } from '../../state/faculty/selector/faculty.selectors';

// *** Services
import { UserService } from 'src/app/services/user.service';
import { CourseService } from '../../../services/course.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'app-edit-professor',
   templateUrl: './edit-professor.component.html',
   styleUrls: ['./edit-professor.component.scss'],
})
export class EditProfessorComponent implements OnInit, OnDestroy {
   /**
    * Variable used to store FormGroup values that are being edited on modal.
    */
   professorEdit: FormGroup;
   professorId;

   /**
    * Variable used to store faculties of institution.
    */
   faculties;
   faculties$;

   /**
    * Variable used to store departments of institution.
    */
   departments;
   departments$;

   /**
    * Variable used to store subjects of institution.
    */
   subjects;
   subjects$;

   /**
    * Variable stores configuration of ngx-select-dropdown.
    */
   config;

   /**
    * Variable used to store data of professor that is being edited.
    */
   professor;

   /**
    * Variable used to store state if word allocation started or not so more inputs will be shown to administrator.
    */
   allocateWordsStart = false;

   /**
    * Variable is used to store a value true or false so in  @function calculateWords() to increase or decrease words.
    */
   wordsIncrease;

   /**
    * Variable used to store a string for UI that displays a text that changes from a wordsIncrease value.
    */
   allocateFieldText: string;

   /**
    * Variable used to store number o words that are given on input by administrator.
    */
   wordsToAllocate;

   /**
    * Variable used to store number of maximum words that can be allocated or decreased by administrator.
    */
   maxWords;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer;
   /**
    * Boolean used to store a value after email is writen to check if there is same email or not on database
    */
   emailExists: boolean = false;
   constructor(
      public dialogRef: MatDialogRef<EditProfessorComponent>,
      public dialog: MatDialog,
      private departmentService: DepartmentService,
      private fb: FormBuilder,
      private userService: UserService,
      private store: Store,
      private courseService: CourseService,
      private spinner: NgxSpinnerService,
      public translate: TranslateService,
      /**
       * Used to pass data in component through dialog
       */
      @Inject(MAT_DIALOG_DATA) public data: any
   ) {}

   ngOnDestroy(): void {
      this.faculties$.unsubscribe();
   }

   ngOnInit(): void {
      this.wordsToAllocate = 0;
      this.config = {
         displayKey: 'title', //if objects array passed which key to be displayed defaults to description
         search: true, //true/false for the search functionality defaults to false,
         height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
         placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
         limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
         moreText: 'more', // text to be displayed when more than one items are selected like Option 1 + 5 more
         noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
         searchPlaceholder: 'Search', // label thats displayed in search input,
         searchOnKey: 'title', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
         uniqueKey: 'id'
      };
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.professorEdit = this.fb.group({
         professorName: new FormControl('', [Validators.required, Validators.minLength(3)]),
         professorEmail: new FormControl('', [Validators.required, Validators.email]),
         professorFaculty: new FormControl('', Validators.required),
         professorDepartment: new FormControl('', Validators.required),
         professorSubjects: new FormControl(''),
         professorStatus: new FormControl('', Validators.required),
         role: new FormControl('', Validators.required),
         professorAllocatedWords: new FormControl('', Validators.required),
         currentAllocated: new FormControl('', Validators.required),
         unUsedWords: new FormControl('', Validators.required),
         institutionWordsAvailable: new FormControl('', Validators.required),
      });

      this.professorId = this.data.professorId;

      // Subscribe to faculties first
      this.faculties$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
               console.log('faculties', this.faculties);

               // Load professor data after faculties are available
               this.loadProfessorData();
            }
         });
   }

   private loadProfessorData() {
      this.userService
         .userInfo(this.professorId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.professor = data.user;
               this.professor.subjects = [];

               // Get departments first before setting form values
               if (this.professor.Faculty?.id) {
                  this.departmentService
                     .getDepartmentsOfFaculty(this.professor.Faculty.id)
                     .pipe(first())
                     .subscribe(
                        (deptData) => {
                           this.departments = deptData.departments;

                           // Now set form values after we have departments
                           this.professorEdit.patchValue({
                              professorName: this.professor.name,
                              professorEmail: this.professor.email,
                              professorFaculty: this.professor.Faculty?.id || '-1',
                              professorDepartment: this.professor.Department?.id,
                              professorSubjects: this.professor.subjects,
                              professorStatus: this.professor.status,
                              role: this.professor.roleId,
                              professorAllocatedWords: this.professor.wordsToUse + this.professor.wordsUsed,
                              currentAllocated: this.professor.wordsToUse + this.professor.wordsUsed + this.wordsToAllocate,
                              unUsedWords: this.professor.wordsToUse + this.wordsToAllocate,
                              institutionWordsAvailable: this.professor.Institution.wordsLeft - this.wordsToAllocate,
                           });
                           // Load courses
                           this.loadCoursesAndUpdateFaculty();
                        },
                        (error) => {
                           console.log('error', error);
                        }
                     );
               } else {
                  // If no faculty, just set form values
                  this.professorEdit.patchValue({
                     professorName: this.professor.name,
                     professorEmail: this.professor.email,
                     professorFaculty: '-1',
                     professorDepartment: '-1',
                     professorSubjects: this.professor.subjects,
                     professorStatus: this.professor.status,
                     role: this.professor.roleId,
                     professorAllocatedWords: this.professor.wordsToUse + this.professor.wordsUsed,
                     currentAllocated: this.professor.wordsToUse + this.professor.wordsUsed + this.wordsToAllocate,
                     unUsedWords: this.professor.wordsToUse + this.wordsToAllocate,
                     institutionWordsAvailable: this.professor.Institution.wordsLeft - this.wordsToAllocate,
                  });

                  // Load courses
                  this.loadCoursesAndUpdateFaculty();
               }
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   private loadCoursesAndUpdateFaculty() {
      this.courseService
         .courses(undefined, 0)
         .pipe(first())
         .subscribe(
            (data) => {
               this.subjects = data.courses;
               const assignedCourseIds = new Set(
                  this.professor.professorCourses.map(pc => pc.courseId)
               );
               this.subjects = this.subjects.filter(course => !assignedCourseIds.has(course.id));
               this.professor.subjects = this.professor.professorCourses.map(pc => pc.Course);
               this.form.professorSubjects.setValue(this.professor.subjects);
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method use to get form controls.
    */
   get form() {
      return this.professorEdit.controls;
   }

   /**
    * Method is used to get faculty id from dropdown and then to retrieve departments of that faculty.
    */
   facultyChanged() {
      let facultyId = this.form.professorFaculty.value;
      if (!facultyId || facultyId === '-1') return;

      const currentDepartmentId = this.form.professorDepartment.value;
      console.log(currentDepartmentId, 'currentDepartmentId');

      this.departmentService
         .getDepartmentsOfFaculty(facultyId)
         .pipe(first())
         .subscribe(
            (data) => {
               this.departments = data.departments;
              console.log(data.departments, 'departments');

               // Keep current department if it exists in new department list
               const departmentExists = this.departments.some(dept => dept.id === currentDepartmentId);
               if (!departmentExists && this.departments.length > 0) {
                  this.form.professorDepartment.setValue(this.departments[0].id);
               }
            },
            (error) => {
               console.log('error', error);
            }
         );
   }

   /**
    * Method is used to call editCourse service that will send updated fields of department to API.
    */
   async update() {
      const result = await swal.fire({
         title: 'Confirm update of Educator',
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.confirm'),
         cancelButtonText: this.translate.instant('report.cancel'),
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            professorActions.editProfessorDetails({
               professorId: this.professorId,
               professorName: this.form.professorName.value,
               professorEmail: this.form.professorEmail.value,
               professorFaculty: this.form.professorFaculty.value,
               professorDepartment: this.form.professorDepartment.value,
               professorStatus: this.form.professorStatus.value,
               increaseWords: this.wordsIncrease,
               professorWordsToAllocate: this.wordsToAllocate,
               professorSubjects: this.form.professorSubjects.value,
               page: this.data.page,
               faculty: this.data.faculty,
               department:
                  this.data.department,
               professorNameFilter: this.data.professorName,
               role: this.form.role.value,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }

   /**
    * Method is used to call remove course service.
    */
   async delete() {
      const result = await swal.fire({
         title: this.translate.instant('new_entries.delete_educator_modal_title'),
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#b5adad',
         confirmButtonText: this.translate.instant('app.confirm'),
         cancelButtonText: this.translate.instant('report.cancel'),
         allowOutsideClick: false,
      });
      if (result.isConfirmed) {
         this.store.dispatch(
            professorActions.removeProfessor({
               professorId: this.professorId,
               page: this.data.page,
               faculty: this.data.faculty,
               department:
                  this.data.department,
               professorNameFilter: this.data.professorName,
            })
         );
         this.dialogRef.close();
      } else if (result.isDismissed) {
         this.dialogRef.close();
      }
   }

   /**
    * Method is used to call a api to check if emails exist on database or not.
    */
   // validateEmail() {
   //    let time;
   //    time = 300;
   //    clearTimeout(this.timer);
   //    if (
   //       this.form.professorEmail.value.length !== 0 &&
   //       this.professor.email !== this.form.professorEmail.value
   //    ) {
   //       this.timer = setTimeout(() => {
   //          this.spinner.show();
   //          this.userService
   //             .checkEmail(this.form.professorEmail.value)
   //             .pipe(first())
   //             .subscribe(
   //                (data) => {
   //                   if (data.sameEmails !== 0) {
   //                      this.emailExists = true;
   //                   } else {
   //                      this.emailExists = false;
   //                   }
   //                   this.spinner.hide();
   //                },
   //                (error) => {
   //                   this.spinner.hide();
   //                   console.log('error', error);
   //                }
   //             );
   //       }, time);
   //    } else {
   //       this.emailExists = false;
   //    }
   // }

   compareFn(option: string, value: string): boolean {
       return option === value;
   }
}
