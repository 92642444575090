<section class="admin_settings">
  <h1>{{ 'new_entries.settings_and_preferences' | translate }}</h1>
  <p>{{ 'new_entries.customize_analysis' | translate }} <a href="https://support.inspera.com/hc/en-us/articles/20849970302365-Administrator-Institutional-Settings" target="_blank">{{ 'new_entries.learn_more' | translate }}</a></p>

  <form [formGroup]="admin_settings">

    <!-- Originality Feature Configuration -->
     <h2>Originality Feature Configuration</h2>
     <p class="sub_heading">These settings control the configuration and system-wide availability of originality-checking features</p>

     <!-- Translation Similarity -->
    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.translation_similarity' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.translation_similarity_languages' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" [formControl]="translatedLanguageEnabled" />
          <span class="slider round"></span>
        </label>
      </section>
      <section class="body" *ngIf="translatedLanguageEnabled.value">
        <p>{{ 'new_entries.select_languages' | translate }}</p>
        <section class="language-selection">
          <mat-form-field>
            <mat-select [formControl]="selectedTranslatedLanguages" multiple>
              <mat-option *ngFor="let  language of languages" [value]="language.value" [disabled]="selectedTranslatedLanguages.value.length >= 3 && !isSelected(language.value)">
                {{ language.text }}
              </mat-option>
            </mat-select>
            <mat-hint *ngIf="selectedTranslatedLanguages.value.length > 0">
              {{ selectedTranslatedLanguages.value.length }}/3 {{ 'new_entries.selected' | translate }}
            </mat-hint>
          </mat-form-field>
        </section>
        <section class="invalid" *ngIf="selectedTranslatedLanguages.errors?.maxlength">
          You can select up to 3 languages only.
        </section>
      </section>
    </section>

    <!-- AI Prediction -->
    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'general.enable_ai_prediction' | translate }}:
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.ai_prediction_capability' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="aiDetection" />
          <span class="slider round"></span>
        </label>
      </section>
    </section>

    <!-- Contextual Similarity -->
    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.contextual_similarity_threshold' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.contextual_similarity_in_report' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="contextualSimilarity" />
          <span class="slider round"></span>
        </label>
      </section>
      <div class="thresholds_container" *ngIf="form.contextualSimilarity.value">
        <h4>{{ 'new_entries.contextual_similarity_threshold' | translate }}</h4>
        <app-small-info
        [title]="'Threshold defines the percentage at which a sentence in the submitted document is classified as contextually similar when compared to a sentence in the matching source.'"
        [description]="'By default, the contextual similarity threshold is 80%. The threshold can be adjusted but not lowered below 50% to prevent broad or inaccurate matches. Any matches from the set threshold up to 100% will be flagged. For example, if set to 60%, matches between 60% and 100% will be flagged for contextual similarity.'">
        </app-small-info>
        <app-custom-slider
        [handles]="translationHandles"
        [defaultValues]="translationDefaultValues"
        [rangeColors]="translationRangeColors"
        [showPercentageMarkers]="true"
        [showHandleValues]="true"
        [handleColors]="['#bcbcbc', '#F2A202']"
        [trackHeight]="'25px'"
        [handleHeight]="'50px'"
        [handleWidth]="'8px'"
        (handleChange)="onTranslationHandleChange($event)">
      </app-custom-slider>
      </div>
    </section>

    <!-- Threshold Configuration -->
    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.enable_thresholds' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'Thresholds define the percentage at which flagged sentences are categorized as exact matches or potentially altered text. By adjusting these thresholds, you can refine the sensitivity of the originality report. Higher thresholds may increase precision, while lower thresholds could allow for broader comparisons. '">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="enableThresholds" />
          <span class="slider round"></span>
        </label>
      </section>
        <div class="thresholds_container" *ngIf="this.form.enableThresholds.value">
          <!-- Similarity Thresholds -->
           <div class="thresholds_title">
            <button (click)="openLink('https://support.inspera.com/hc/en-us/articles/23841404707869-Inspera-Originality-Thresholds')">Learn More</button>
           </div>
          <!-- <section class="threshold-section"> -->
            <h4>Exact Match and Altered Text</h4>
            <app-small-info
            [title]="'Thresholds define the percentage at which a sentence in the submitted document is classified as an Exact Match or Possibly Altered Text when compared to a sentence in the matching source.'"
            [description]="'The default threshold for Possibly Altered Text is 60%–80%, and 80%–100% for Exact Match. We recommend using the default settings, as lowering the thresholds may result in broader and less accurate matches.'">
            </app-small-info>
            <app-custom-slider
            [handles]="similarityHandles"
            [defaultValues]="similarityDefaultValues"
            [rangeColors]="similarityRangeColors"
            [showPercentageMarkers]="true"
            [showHandleValues]="true"
            [handleColors]="['#2994FF', '#ED457D']"
            [trackHeight]="'25px'"
            [handleHeight]="'50px'"
            [handleWidth]="'8px'"
            (handleChange)="onSimilarityHandleChange($event)">
          </app-custom-slider>
          <!-- </section> -->

          <!-- Contextual Similarity Threshold - only show if contextualSimilarity is enabled -->
          <!-- <section class="threshold-section" *ngIf="form.contextualSimilarity.value"> -->
          <!-- </section> -->
        </div>
    </section>


     <!-- Access and Permissions Settings -->
      <h2>Access and Permissions Settings</h2>
      <p class="sub_heading">These settings control the system-wide availability of additional features and capabilities</p>

      <!-- Educator User Management Permissions -->
      <section class="single_form_input">
        <section class="header">
          <h3>
            Educator User Management Permissions:
            <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'Enable or disable educators ability to add students to the institution.'">
              <img src="../../../../assets/report_v2/info.svg" alt="">
            </button>
          </h3>
          <label class="switch">
            <input type="checkbox" formControlName="canRegisterStudents" />
            <span class="slider round"></span>
          </label>
        </section>
      </section>

      <!-- Submission Pre Check -->
      <section class="single_form_input">
        <section class="header">
          <h3>
            {{ 'new_entries.submission_pre_check' | translate }}
            <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.allow_pre_checks' | translate">
              <img src="../../../../assets/report_v2/info.svg" alt="">
            </button>
          </h3>
          <label class="switch">
            <input type="checkbox" formControlName="submissionPreCheck" />
            <span class="slider round"></span>
          </label>
        </section>
      </section>

    <!-- Add Document to Archive -->
    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.archive_documents' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.archive_assignments' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="archive"/>
          <span class="slider round"></span>
        </label>
      </section>
    </section>

    <!-- Content Access -->
    <section class="single_form_input">
      <section class="header">
        <h3>
           {{ 'new_entries.content_access' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'new_entries.access_content_tooltip' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <select name="" id="" formControlName="contentAccess" class="outlined">
          <option value="restricted">{{ 'new_entries.restricted' | translate }}</option>
          <option value="open">{{ 'new_entries.open' | translate }}</option>
          <option value="closed">{{ 'new_entries.closed' | translate }}</option>
        </select>
      </section>
      <section *ngIf="selectedContentValue" class="info">
        <img src="../../../assets/report_v2/info-blue.svg" alt="">
        <p>{{ (selectedContentValue === 'restricted' ? 'new_entries.restricted_info' : (selectedContentValue === 'open' ? 'new_entries.opened_info' : 'new_entries.close_info')) | translate }}</p>
        <a href="https://support.inspera.com/hc/en-us/articles/21237980575005-Access-Content-Feature" target="_blank">{{ 'new_entries.learn_more' | translate }}</a>
      </section>

    </section>

    <!-- Personal Identifiable Information -->
    <section class="single_form_input">
      <section class="header">
        <h3>
          {{ 'new_entries.personal_identifiable_information' | translate }}
          <button matTooltipClass="new_report_tooltip" matTooltipPosition="above" [matTooltip]="'pii_entities.tooltip' | translate">
            <img src="../../../../assets/report_v2/info.svg" alt="">
          </button>
        </h3>
        <label class="switch">
          <input type="checkbox" formControlName="personalIdentifiableInformation" />
          <span class="slider round"></span>
        </label>
      </section>
      <section class="chips" *ngIf="admin_settings.controls.personalIdentifiableInformation.value">
        <section>
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let chip of admin_settings.controls.personalIdentifiableInformationInfo.value" [selectable]="true" [removable]="true" (removed)="removeChip(chip)">
              {{('pii_entities.' + chip.title.toLowerCase().replaceAll(' ', '_')) | translate}}
              <mat-icon matChipRemove>
                <img src="../../../assets/report_v2/blue-x-circle.svg" alt="">
              </mat-icon>
            </mat-chip>
          </mat-chip-list>
          <p class="zero_state" *ngIf="!admin_settings.controls.personalIdentifiableInformationInfo.value.length && !showChipInput">
            {{ 'pii_entities.zero_state' | translate }}
          </p>
        </section>
        <section class="btns_chips">
          <button *ngIf="!showChipInput" (click)="toggleChipInput()" #addMoreButton>+ {{'new_entries.add_more' | translate }}</button>
          <input
          *ngIf="showChipInput"
          [matAutocomplete]="auto"
          #chipInput
          (focus)="openAutocomplete()"
          [matAutocompleteDisabled]="!showChipInput"
          matAutocompletePosition="below"
          [placeholder]="'new_entries.add_more' | translate"
          style="padding-inline: 10px;"
          (keydown)="preventInput($event)">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addChipFromAutocomplete($event); $event.option.deselect()">
            <mat-option *ngFor="let option of allChipOptions" [value]="option">
              {{('pii_entities.' + option.title.toLowerCase().replaceAll(' ', '_')) | translate}}
            </mat-option>
          </mat-autocomplete>
        </section>
      </section>
    </section>

    <section class="form_btns">
      <button class="cancel_btn" (click)="cancel()" [disabled]="!isFormChanged" [ngClass]="{'disabled': !isFormChanged}">{{ 'general.reset' | translate }}</button>
      <button class="submit_btn" (click)="submitSettings()" [disabled]="!isFormChanged" [ngClass]="{'disabled': !isFormChanged}">{{ 'general.submit' | translate }}</button>

    </section>

  </form>
</section>
