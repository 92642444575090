// *** Angular
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as professorActions from '../state/professor/action/professor.actions';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

// *** Actions
import * as facultyActions from '../state/faculty/action/faculty.actions';

// *** Components
import { EditProfessorComponent } from '../modals/edit-professor/edit-professor.component';

// *** Selectors
import { getInstitutionProfessorsStateDetails } from '../state/professor/selector/professor.selectors';
import { getInstitutionFacultiesStateDetails } from '../state/faculty/selector/faculty.selectors';

// *** Services
import { DepartmentService } from 'src/app/services/department.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CsvExportService } from 'src/app/services/csv-export.service';
import { ExcelExportService } from 'src/app/services/excel-export.service';
import { PdfExportService } from 'src/app/services/pdf-export.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { RegisterEducatorFormComponent } from '../register-educator-form/register-educator-form.component';

@Component({
   selector: 'app-units-professors',
   templateUrl: './units-professors.component.html',
   styleUrls: ['./units-professors.component.scss'],
})
export class UnitsProfessorsComponent implements OnInit, OnDestroy {
   /**
    * Variable is used to store retrieved professors from service.
    */
   professors: any;
   professors$;
   /**
    * Symbolizing the page of pagination
    */
   page: number = 1;
   /**
    * Symbolizing the size of the page
    */
   professorsNumber: number;
   /**
    * Variabel used to retrieve faculties of insitution
    */
   faculties;
   /**
    * Variable is used to store facultyId to filter thesis of specific faculty.
    */
   facultyId = '-1';
   /**
    * Variable is used to store departmentId that will be used to filter thesis of specific department
    */
   departmentId = '-1';
   /**
    * Variabel used to store professor name that used to filter professors by name
    */
   name: any;
   /**
    * Variable is used to store departments of selected faculty
    */
   departments: any;
   /**
    * Variable used to store a time for function to trigger.(x time after key is up call y function.)
    */
   timer: any;
   getInstitutionFacultiesStateDetails$: any;
   @ViewChild('contentToCapture') contentToCapture: ElementRef;
   constructor(
      public dialog: MatDialog,
      private store: Store,
      private departmentService: DepartmentService,
      private csvExportService: CsvExportService,
      private excelExportService: ExcelExportService,
      private pdfExportService: PdfExportService,
      private spinner: NgxSpinnerService,
      private userService: UserService,
      private translate: TranslateService
   ) { }
   ngOnDestroy(): void {
      this.getInstitutionFacultiesStateDetails$.unsubscribe();
      this.professors$.unsubscribe();
   }
   ngOnInit(): void {
      this.store.dispatch(facultyActions.loadFacultiesDetails({ page: 0 }));

      this.getInstitutionFacultiesStateDetails$ = this.store
         .select(getInstitutionFacultiesStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.faculties = data.faculties;
            }
         });

      this.store.dispatch(
         professorActions.loadProfessorsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorName: this.name,
         })
      );

      this.professors$ = this.store
         .select(getInstitutionProfessorsStateDetails)
         .subscribe((data) => {
            if (data !== null) {
               this.professors = data.professors;
               this.professorsNumber = data.professorsNumber;
            }
            if (data.professorRegistered !== null) {
               this.resetFilter();
               this.store.dispatch(
                  professorActions.setProfessorRegisterToNull()
               );
            }
         });
   }

   /**
    * Method used to change pagination page. It dispatches an action with page as param.
    * @param event
    */
   changePage(event): void {
      this.page = event;
      this.store.dispatch(
         professorActions.loadProfessorsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorName: this.name,
         })
      );
   }

   /**
    * Method is used to open a modal that will contain a professor information to edit or delete.
    * @param id
    */
   openModal(id) {
      this.dialog.open(EditProfessorComponent, {
         data: {
            professorId: id,
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorName: this.name,
         },
         width: '50%',
         height: '95%',
      });
   }

   /**
    * Method is used to open a modal that will contain a professor information to register professors.
    */
   registerProfessorModal() {
      this.dialog.open(RegisterEducatorFormComponent, {
         width: '50%',
         height: '95%',
      });
   }

   /**
    * Function is used to filter professors by faculty
    */
   selectedFaculty() {
      this.page = 1;
      this.departmentId = '-1';
      this.store.dispatch(
         professorActions.loadProfessorsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorName: this.name,
         })
      );
      if (this.facultyId !== '') {
         this.departmentService
            .getDepartmentsOfFaculty(this.facultyId)
            .pipe(first())
            .subscribe(
               (data) => {
                  this.departments = data.departments;
               },
               (error) => {
                  console.log(error);
               }
            );
      }
   }
   /**
    * Function is used to filter professors by faculty
    */
   departmentFilter() {
      this.page = 1;
      this.store.dispatch(
         professorActions.loadProfessorsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorName: this.name,
         })
      );
   }
   /**
    * Function is used to filter professors by name
    */
   titleFilter() {
      let time = 500;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
         this.page = 1;
         this.store.dispatch(
            professorActions.loadProfessorsDetails({
               page: this.page,
               faculty: this.facultyId == '-1' ? undefined : this.facultyId,
               department:
                  this.departmentId == '-1' ? undefined : this.departmentId,
               professorName: this.name,
            })
         );
      }, time);
   }
   /**
    * Function is used to reset filters
    */
   resetFilter() {
      this.facultyId = '-1';
      this.departmentId = '-1';
      this.name = undefined;
      this.page = 1;
      this.store.dispatch(
         professorActions.loadProfessorsDetails({
            page: this.page,
            faculty: this.facultyId == '-1' ? undefined : this.facultyId,
            department:
               this.departmentId == '-1' ? undefined : this.departmentId,
            professorName: this.name,
         })
      );
   }

   prepareExportData(data) {
      let rows = [
         ["Educators"],
         [""],
         [
            "Educator name",
            "Email",
            "School",
            "Department",
            "Subjects",
            "Status",
         ],
      ]

      data.professors.forEach(professor => {
         rows.push([
            professor.name,
            professor.email,
            professor.Faculty.name,
            professor.Department.name,
            professor.professorCourses?.length,
            professor.status ? this.translate.instant('general.active') : 'Inactive',
         ]);
      });

      return rows
   }

   exportToCSV() {
      this.spinner.show();
      this.userService.institutionProfessors(
         -1,
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId == '-1' ? undefined : this.departmentId,
         this.name,
      ).subscribe((data) => {
         const rows = this.prepareExportData(data)
         this.csvExportService.exportToCSV(rows, 'Educators');
         this.spinner.hide();
      })
   }

   exportToExcel() {
      this.spinner.show();
      this.userService.institutionProfessors(
         -1,
         this.facultyId == '-1' ? undefined : this.facultyId,
         this.departmentId == '-1' ? undefined : this.departmentId,
         this.name,
      ).subscribe((data) => {
         const rows = this.prepareExportData(data)
         this.excelExportService.exportToExcel(rows, 'Educators');
         this.spinner.hide();
      })
   }

   exportToPDF() {
      this.spinner.show()
      if (this.contentToCapture) {
         this.pdfExportService.captureAndExportPDF(this.contentToCapture.nativeElement, 'Educators.pdf');
         this.spinner.hide()
      } else {
         console.error('Content to capture is not available yet.');
         this.spinner.hide()
      }
   }
}
