import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { BulkRegisterService } from '../services/bulk-register.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard implements CanDeactivate<any> {
  constructor(private bulkRegisterService: BulkRegisterService) {}

  canDeactivate(): boolean {
    const registrationState = this.bulkRegisterService.getCurrentProgress();
    
    if (registrationState.isInProgress) {
      return window.confirm('Registration is in progress. Are you sure you want to leave? Your progress will be lost.');
    }
    return true;
  }
} 