<ng-container *ngIf="data.hasAccess && !data.accessDenied">
<div style="padding-bottom: 2%">

   <div>
      <span class="documentInformation"> {{ 'report.source_information' | translate }} </span>
   </div>
   <div style="display: flex">
      <div style="width: 50%">
         <span class="documentInformation"> {{ 'report.document_information' | translate }} </span>
         <div class="center">
            <span style="font-weight: 600">{{ 'app.upload.document_title' | translate }}: </span>
            <span> {{ this.data.submission.title }}</span>
         </div>
         <div class="center">
            <span style="font-weight: 600">{{ 'individual.upload.date_of_upload' | translate }}: </span>
            <span> {{ this.data.submission.createdAt.split('T')[0] }}</span>
         </div>
      </div>
      <div style="width: 50%">
         <span class="documentInformation"> {{ 'report.user_information' | translate }} </span>
         <div class="center">
            <span style="font-weight: 600">{{ 'report.user_name' | translate }}: </span>
            <span> {{ this.data.submissionUserDetails.name }}</span>
         </div>
         <div class="center">
            <span style="font-weight: 600">{{ 'report.user_institution' | translate }}: </span>
            <span>
               {{
                  this.data.submissionUserDetails.Institution?.name || '//'
               }}</span
            >
         </div>
      </div>
   </div>
</div>

   <div class="content_wrapper">
      <pdf-viewer
         id="viewer"
         [src]="pdfSrc"
         [render-text]="true"
         [fit-to-page]="true"
         (after-load-complete)="callBackFn($event)"
         style="
            display: block;
            overflow: auto;
            height: 100%;
            scroll-behavior: smooth;
            border-radius: 10px;
         "
      ></pdf-viewer>
   </div>
</ng-container>

<ng-container *ngIf="data.accessDenied">
  <div class="access_denied">
    {{ 'new_entries.access_denied' | translate }}
    <button mat-dialog-close>{{ 'aria-labels.go_back' | translate }}</button>
  </div>
</ng-container>

<ng-container *ngIf="!data.hasAccess && !data.accessDenied">
   <div class="request_access">
      <h3>{{ 'new_entries.request_access_to_document' | translate }}</h3>
      <p>{{ 'new_entries.stored_in_different_institution' | translate }}</p>
      <div class="req_input">
         <h4>{{ 'new_entries.request_purpose' | translate }}</h4>
         <input [(ngModel)]="requestPurpose" type="text">
      </div>
      <h6>{{ 'new_entries.specify_purpose_of_request' | translate }}</h6>
      <div class="btns">
         <button mat-dialog-close class="close_btn">{{ 'aria-labels.close' | translate }}</button>
         <button class="submit_btn" (click)="submit()">{{ 'new_entries.submit_request' | translate }}</button>
      </div>
   </div>
</ng-container>
