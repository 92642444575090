import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin/super-admin.service';

@Component({
  selector: 'app-administrator-settings',
  templateUrl: './administrator-settings.component.html',
  styleUrls: ['./administrator-settings.component.scss']
})
export class AdministratorSettingsComponent implements OnInit {
  settingsForm: FormGroup;
  languages = [
    { text: 'English', value: 'en' },
    { text: 'Albanian', value: 'sq' },
    { text: 'German', value: 'de' },
    { text: 'Italian', value: 'it' },
    { text: 'French', value: 'fr' },
    { text: 'Spanish', value: 'es' },
    { text: 'Greek', value: 'el' },
    { text: 'Czech', value: 'cs' },
    { text: 'Turkish', value: 'tr' },
    { text: 'Slovak', value: 'sk' },
    { text: 'Lithuanian', value: 'lt' },
    { text: 'Latvian', value: 'lv' },
    { text: 'Polish', value: 'pl' },
    { text: 'Serbian', value: 'sr' },
    { text: 'Macedonian', value: 'mk' },
    { text: 'Portuguese', value: 'pt' },
    { text: 'Dutch', value: 'nl' },
    { text: 'Russian', value: 'ru' },
    { text: 'Bulgarian', value: 'bg' },
    { text: 'Hungarian', value: 'hu' },
    { text: 'Romanian', value: 'ro' },
    { text: 'Slovenian', value: 'sl' },
    { text: 'Swedish', value: 'sv' },
    { text: 'Finnish', value: 'fi' },
    { text: 'Croatian', value: 'hr' },
    { text: 'Bosnian', value: 'bs' },
    { text: 'Norwegian', value: 'no' },
    { text: 'Danish', value: 'da' },
    { text: 'Estonian', value: 'et' }
  ];

  constructor(
    private fb: FormBuilder,
    private superAdminService: SuperAdminService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.settingsForm = this.fb.group({
      enableTranslations: [false],
      selectedTranslatedLanguages: [[], Validators.maxLength(3)],
      aiTextPrediction: [false],
      archive: [false],
      excludeSourcesPercentage: [null, [Validators.min(0), Validators.max(100)]],
      anonymousSubmission: [false],
      excludeCitations: [false],
      submissionPreCheck: [false],
      enableThresholds: [false],
      exactMatchThreshold: [{ value: null, disabled: true }, [Validators.min(0), Validators.max(100)]],
      alteredTextThreshold: [{ value: null, disabled: true }, [Validators.min(0), Validators.max(100)]],
      contextualSimilarity: [false],
      contextualSimilaritiesThreshold: [{ value: null, disabled: true }, [Validators.min(0), Validators.max(100)]]
    });

    this.settingsForm.get('enableTranslations').valueChanges.subscribe(enabled => {
      if (enabled) {
        this.settingsForm.get('selectedTranslatedLanguages').enable();
      } else {
        this.settingsForm.get('selectedTranslatedLanguages').disable();
        this.settingsForm.get('selectedTranslatedLanguages').reset();
      }
    });

    this.settingsForm.get('enableThresholds').valueChanges.subscribe(enabled => {
      if (enabled) {
        this.settingsForm.get('exactMatchThreshold').enable();
        this.settingsForm.get('alteredTextThreshold').enable();
      } else {
        this.settingsForm.get('exactMatchThreshold').disable();
        this.settingsForm.get('exactMatchThreshold').reset();
        this.settingsForm.get('alteredTextThreshold').disable();
        this.settingsForm.get('alteredTextThreshold').reset();
      }
    });

    this.settingsForm.get('contextualSimilarity').valueChanges.subscribe(enabled => {
      if (enabled) {
        this.settingsForm.get('contextualSimilaritiesThreshold').enable();
      } else {
        this.settingsForm.get('contextualSimilaritiesThreshold').disable();
        this.settingsForm.get('contextualSimilaritiesThreshold').reset();
      }
    });
  }

  isSelected(value: string): boolean {
    return this.settingsForm.get('selectedTranslatedLanguages').value.includes(value);
  }

  onSubmit(): void {
    if (this.settingsForm.valid) {
      const formValues = this.settingsForm.value;
      const settings = {
        ai: formValues.aiTextPrediction ? 1 : 0,
        archive: formValues.archive ? 1 : 0,
        originalLanguage: formValues.selectedTranslatedLanguages[0] || 'en',
        exclude_citations: formValues.excludeCitations ? 1 : 0,
        translatedLanguage: formValues.selectedTranslatedLanguages.length ? formValues.selectedTranslatedLanguages : null,
        submission_pre_check: formValues.submissionPreCheck ? 1 : 0,
        anonymous_submissions: formValues.anonymousSubmission ? 1 : 0,
        source_percentage_threshold: formValues.excludeSourcesPercentage || 0,
        contextualSimilarity: formValues.contextualSimilarity || 0,
        thresholds: {
          exact_match: formValues.exactMatchThreshold || 0,
          altered_text: formValues.alteredTextThreshold || 0,
          contextual_similarity: formValues.contextualSimilaritiesThreshold || 0
        },
        contentAccess: formValues.contentAccess,
        institutionsContentAccess: formValues.institutionsContentAccess
      };
      this.superAdminService.createInstitution(
        settings,
        undefined,
        undefined,
        undefined,
        true
      ).subscribe(
        response => {
          this.toastr.success(this.translateService.instant('new_entries.institution_created_successfully'));
        },
        error => {
          this.toastr.error(this.translateService.instant('new_entries.institution_creation_error'));
          console.error(error);
        }
      );
    } else {
      console.log('Form is invalid, please correct the errors.');
    }
  }

}
