 <div class="register-professor shadowBorder">
    <div class="addSubject">
   <div>
    <h4 class="bold">{{ 'app.register_subjects' | translate }}</h4>
    <!-- <div class="nav">
       <a (click)="toggleForm('manual')" [ngClass]="{ active: this.excel === false }"
          >{{ 'general.manual_form' | translate }}</a
       >
       <a (click)="toggleForm('bulk')" [ngClass]="{ active: this.excel === true }" class="disable"
          >{{ 'app.upload.bulk_form' | translate }}</a
       >
    </div> -->
    <ng-container *ngIf="this.excel === false">
       <form action="" [formGroup]="subjectRegister">
          <div formArrayName="subject">
             <ng-container
                *ngFor="let subject of subjectForm.controls; let i = index"
                [formGroupName]="i"
             >
             <section class="add_subject_form">
               <h3 class="h-40">{{ 'general.subject_name' | translate }}</h3>
               <div>
                  <input class="w-100" formControlName="subjectName" type="text" />
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectName.touched &&
                        subjectForm.controls[i].controls.subjectName.invalid
                     "
                     class="invalid"
                  >
                     {{ 'general.subject_name' | translate }} {{ 'aria-labels.is_required' | translate }}*
                  </div>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectName.errors
                           ?.minlength
                     "
                     class="invalid"
                  >
                     {{ 'general.subject_name' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
                  </div>
               </div>
               <h3 class="h-40">{{ 'app.institution' | translate }}</h3>
               <div>
                  <select class="w-100"
                  formControlName="subjectFaculty"
                  (change)="facultyChanged(i)"
               >
                  <option value="" selected disabled>{{ 'general.select_institution' | translate }}</option>
 
                  <ng-container
                     *ngFor="let faculty of this.facultiesDetails"
                  >
                     <option value="{{ faculty.id }}">
                        {{ faculty.name }}
                     </option>
                  </ng-container>
               </select>
               <div
                  *ngIf="
                     subjectForm.controls[i].controls.subjectFaculty
                        .touched &&
                     subjectForm.controls[i].controls.subjectFaculty.invalid
                  "
                  class="invalid"
               >
                  {{ 'app.institution' | translate }} {{ 'aria-labels.is_required' | translate }}*
               </div>
               </div>
               <h3 class="h-40">{{ 'general.department' | translate }}</h3>
               <div>
                  <select class="w-100"
                  disabled
                  *ngIf="
                     subjectForm.controls[i].controls.subjectFaculty.value ==
                        '' ||
                     subjectForm.controls[i].controls.subjectFaculty.value ==
                        undefined
                  "
                  name=""
                  id=""
               >
                  <option value="">{{ 'general.select_institution_first' | translate }}</option>
                  </select>
                  <select class="w-100"
                     *ngIf="
                        subjectForm.controls[i].controls.subjectFaculty
                           .value !== '' &&
                        subjectForm.controls[i].controls.subjectFaculty
                           .value !== undefined
                     "
                     formControlName="subjectDepartment"
                     (change)="departmentSelected(i, $event.target.value)"
                  >
                     <option value="" selected disabled>
                        {{ 'general.select_department' | translate }}
                     </option>
 
                     <ng-container
                        *ngFor="let department of this.departments[i]"
                     >
                        <option value="{{ department.id }}">
                           {{ department.name }}
                        </option>
                     </ng-container>
                  </select>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectDepartment
                           .touched &&
                        subjectForm.controls[i].controls.subjectDepartment
                           .invalid
                     "
                     class="invalid"
                  >
                     {{ 'general.department' | translate }} {{ 'aria-labels.is_required' | translate }}*
                  </div>
               </div>
               <h3 class="h-40">{{ 'new_entries.subject_summary' | translate }}</h3>
               <div>
                  <input class="w-100" formControlName="subjectDescription" type="text" />
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectDescription
                           .touched &&
                        subjectForm.controls[i].controls.subjectDescription
                           .invalid
                     "
                     class="invalid"
                  >
                     {{ 'new_entries.subject_summary' | translate }} {{ 'aria-labels.is_required' | translate }}*
                  </div>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectDescription
                           .errors?.minlength
                     "
                     class="invalid"
                  >
                     {{ 'general.subjects' | translate }} {{ 'new_entries.subject_summary' | translate }} {{ 'aria-labels.should_contain_at_least_3_characters' | translate }}!
                  </div>
               </div>
               <!-- <h3 class="h-40">{{ 'app.year_of_study' | translate }}</h3>
               <div>
                  <select class="w-100" formControlName="subjectYear">
                     <option value="" selected disabled>{{ 'app.choose_year' | translate }}</option>
                     <ng-container
                        *ngFor="let year of this.subjectForm.controls[i].controls.subjectYears.value"
                     >
                        <option value="{{ year.index }}">
                           {{ year.index }}
                        </option>
                     </ng-container>
                  </select>
                  <div
                     *ngIf="
                        subjectForm.controls[i].controls.subjectYear.touched &&
                        subjectForm.controls[i].controls.subjectYear.invalid
                     "
                     class="invalid"
                  >
                     {{ 'app.this_field_is_required' | translate }}.
                  </div>
               </div> -->
               <h3 class="h-40">{{ 'general.subject_id' | translate }}</h3>
               <div>
                  <input class="w-100"
                  formControlName="subjectCode"
                  (ngModelChange)="
                     validateCode(
                        subjectForm.controls[i].controls.subjectCode,
                        i
                     )
                  "
                  type="text"
               />
               <mat-spinner *ngIf="showSpinner == i"></mat-spinner>
               <div *ngIf="this.errors.includes(i)" class="invalid">
 
                  {{ 'app.subjects_code_already_exists_please_try_another_one' | translate }}*
               </div>
               <div *ngIf="this.sameCodes.includes(i)" class="invalid">
                  {{ 'app.subjects_code_already_exists_please_try_another_one' | translate }}*
               </div>
               <div
               *ngIf="
                  subjectForm.controls[i].controls.subjectCode.touched &&
                  subjectForm.controls[i].controls.subjectCode.invalid
               "
               class="invalid"
            >
               {{ 'general.subject_id_required' | translate }}*
            </div>
               <div
                  *ngIf="
                     subjectForm.controls[i].controls.subjectCode.errors
                        ?.minlength
                  "
                  class="invalid"
               >
                  {{ 'general.subject_id_should_contain_three_characters' | translate }}!
               </div>
               </div>
 
               <h3 class="h-40">{{ 'general.subject_type' | translate }}</h3>
               <div>
                  <select
                  class="border white-bg select-adm w-100"
                  formControlName="subjectType"
               >
                  <option value="-1" selected disabled>
                     Choose subject type!
                  </option>
                  <option value="1">{{ 'general.mandatory' | translate }}</option>
                  <option value="2">{{ 'general.elective' | translate }}</option>
               </select>
               <div
                  *ngIf="
                     subjectForm.controls[i].controls.subjectType.touched &&
                     subjectForm.controls[i].controls.subjectType.invalid
                  "
                  class="invalid"
               >
                  {{ 'general.subject_type' | translate }} {{ 'aria-labels.is_required' | translate }}*
               </div>
               </div>
             </section>
                <!-- <h3 class="h-40">{{ 'general.academic_year' | translate }}</h3>
                <div>
                   <select
                   class="border white-bg select-adm w-100"
                   formControlName="subjectGenerate"
                >
                   <option value="-1" selected disabled>
                      {{ 'app.select_academic_year' | translate }}.
                   </option>
                   <option *ngFor="let year of generateAcademicYears()" [value]="'g' + year.substr(2, 2) + year.substr(7, 2)">{{ year }}</option>
                </select>
                <div
                   *ngIf="
                      subjectForm.controls[i].controls.subjectGenerate
                         .touched &&
                      subjectForm.controls[i].controls.subjectGenerate.invalid
                   "
                   class="invalid"
                >
                   {{ 'general.academic_year' | translate }} {{ 'aria-labels.is_required' | translate }}*
                </div>
 
                </div> -->
                <ng-container *ngIf="subjectForm.controls.length > 1">
                   <div class="right">
                      <button class="remove" (click)="this.removeSubject(i)">
                         -{{ 'new_entries.remove' | translate }}
                      </button>
                   </div>
                </ng-container>
             </ng-container>
          </div>
          <button (click)="this.addSubject()" class="addMore">
             + {{ 'general.add_new_subject' | translate }}
          </button>
          <div class="center">
             <button
                [disabled]="subjectForm.invalid || this.codeExists"
                [ngClass]="{
                   disable: subjectForm.invalid || this.codeExists
                }"
                class="submit"
                type="submit"
                (click)="submit()"
             >
                {{ 'app.confirm' | translate }}
             </button>
          </div>
       </form>
    </ng-container>
    <ng-container *ngIf="this.excel === true">
       <div class="content">
          <div>
             <select
                (change)="facultyChanged(0)"
                [(ngModel)]="this.selectedFaculty"
             >
                <option selected disabled value="">{{ 'general.select_institution' | translate }}</option>
                <ng-container *ngFor="let faculty of this.facultiesDetails">
                   <option value="{{ faculty.id }}">
                      {{ faculty.name }}
                   </option>
                </ng-container>
             </select>
             <div
                class="border"
                (click)="downloadTemplate()"
                style="cursor: pointer"
             >
                <h6>{{ 'app.download_template' | translate }}</h6>
                <div>
                   <i class="fas fa-download"></i>
                   <p>
                      Download and fill the relevant information within the
                      template
                   </p>
                </div>
             </div>
          </div>
          <div>
             <select
                disabled
                *ngIf="
                   this.selectedFaculty == '' ||
                   this.selectedFaculty == undefined
                "
             >
                <option value="">{{ 'general.select_institution_first' | translate }}</option>
             </select>
             <select
                *ngIf="
                   this.selectedFaculty !== '' &&
                   this.selectedFaculty !== undefined
                "
                [(ngModel)]="this.selectedDepartment"
             >
                <option selected disabled value="">{{ 'general.select_department' | translate }}</option>
                <ng-container *ngFor="let department of this.departments[0]">
                   <option value="{{ department.id }}">
                      {{ department.name }}
                   </option>
                </ng-container>
             </select>
             <div
                class="border"
                (click)="
                   this.selectedFaculty !== undefined &&
                   this.selectedDepartment !== undefined
                      ? upload()
                      : showAlert()
                "
                style="cursor: pointer"
             >
                <div>
                   <h6>{{ 'general.upload_template' | translate }}</h6>
                   <i class="fas fa-upload"></i>
                </div>
                <p>
                   Upload the filled template to migrate your data
                   automatically
                </p>
                <input
                   class="dropzone"
                   type="file"
                   name=""
                   id=""
                   (change)="onFileSelected($event)"
                   accept=".xlsx"
                   style="display: none"
                />
             </div>
          </div>
       </div>
    </ng-container>
    <ng-container *ngIf="this.excel === true && this.showErrors">
       <div class="content">
          <table>
             <tr>
                <th>
                   <h3 class="bold">#</h3>
                </th>
                <th>
                   <h3 class="bold">{{ 'general.error' | translate }}</h3>
                </th>
                <th>
                   <h3 class="bold">{{ 'app.mark_as_fixed' | translate }}</h3>
                </th>
             </tr>
             <tr *ngFor="let error of this.errorFromExcel; let i = index">
                <td>
                   <h3>
                      {{ i + 1 }}
                   </h3>
                </td>
                <td>
                   <h3 id="{{ i + 1 }}">
                      {{ error }}
                   </h3>
                </td>
                <td>
                   <h3 (click)="changeStyle(i + 1)" style="cursor: pointer">
                      Mark
                   </h3>
                </td>
             </tr>
          </table>
       </div>
    </ng-container>
   </div>
    </div>
 </div>
 