import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'app-course-register',
   templateUrl: './course-register.component.html',
   styleUrls: ['./course-register.component.scss'],
})
export class CourseRegisterComponent implements OnInit {

   constructor() {}
  
   ngOnInit(): void {}
 
}
