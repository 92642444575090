<section>
   <main class="flex register-profesori">
      <div class="links">
         <a routerLink="course-register" routerLinkActive="active"
            >{{ 'general.subjects' | translate }}</a>
         <a routerLink="professor-register" routerLinkActive="active"
            >{{ 'general.educators' | translate }}</a>
         <a routerLink="student-register" routerLinkActive="active"
            >{{ 'general.students' | translate }}</a>
      </div>
         <router-outlet></router-outlet>
   </main>
</section>
