import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

interface RegistrationProgress {
  isInProgress: boolean;
  total: number;
  processed: number;
  minimized: boolean;
  currentRoute?: string;
}

interface FailedRegistrationState {
  failedData: any[];
  faculty?: string;
  department?: string;
  registrationType?: 'professor' | 'student' | 'subject';
}

@Injectable({
  providedIn: 'root'
})
export class BulkRegisterService {
  private registrationProgress = new BehaviorSubject<RegistrationProgress>({
    isInProgress: false,
    total: 0,
    processed: 0,
    minimized: false
  });

  private failedRegistrationState = new BehaviorSubject<FailedRegistrationState | null>(null);

  progress$ = this.registrationProgress.asObservable();
  failedState$ = this.failedRegistrationState.asObservable();

  constructor(private router: Router) {
    // Listen to route changes
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((event: NavigationStart) => {
      if (this.registrationProgress.value.isInProgress) {
        // Update current route while keeping registration state
        const current = this.registrationProgress.value;
        this.registrationProgress.next({
          ...current,
          currentRoute: event.url
        });
      }
    });
  }

  startRegistration(total: number) {
    this.registrationProgress.next({
      isInProgress: true,
      total,
      processed: 0,
      minimized: false,
      currentRoute: this.router.url
    });
  }

  updateProgress(processed: number) {
    const current = this.registrationProgress.value;
    this.registrationProgress.next({
      ...current,
      processed
    });
  }

  toggleMinimize() {
    const current = this.registrationProgress.value;
    this.registrationProgress.next({
      ...current,
      minimized: !current.minimized
    });
  }

  completeRegistration() {
    this.registrationProgress.next({
      isInProgress: false,
      total: 0,
      processed: 0,
      minimized: false
    });
  }

  getCurrentProgress(): RegistrationProgress {
    return this.registrationProgress.value;
  }

  setFailedRegistrationState(state: FailedRegistrationState) {
    console.log('Setting failed state:', state);
    this.failedRegistrationState.next(state);
  }

  clearFailedRegistrationState() {
    this.failedRegistrationState.next(null);
  }

  async navigateToFailedRegistrations(registrationType: 'professor' | 'student' | 'subject') {
    const baseRoute = '/administrator/register-module';
    let targetRoute: string;

    switch (registrationType) {
      case 'professor':
        targetRoute = `${baseRoute}/professor-register/bulk-register`;
        break;
      case 'student':
        targetRoute = `${baseRoute}/student-register/bulk-register`;
        break;
      case 'subject':
        targetRoute = `${baseRoute}/course-register/bulk-register`;
        break;
      default:
        console.error('Invalid registration type');
        return;
    }

    console.log('Navigating to:', targetRoute);
    try {
      await this.router.navigate([targetRoute]);
      console.log('Navigation completed');
    } catch (error) {
      console.error('Navigation failed:', error);
    }
  }
} 