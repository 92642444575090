<section>
   <ng-container class="register-profesori">
      <div class="links">
         
         <a routerLink="form-register" routerLinkActive="active"
            >{{ 'general.manual_register' | translate }}</a>

         <a routerLink="bulk-register" routerLinkActive="active"
            >{{ 'general.bulk_register' | translate }}</a>

      </div>
 
   </ng-container>
</section>

<div class="shadowBorder">
   <router-outlet></router-outlet>
</div>