import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-small-info',
  templateUrl: './small-info.component.html',
  styleUrls: ['./small-info.component.scss']
})
export class SmallInfoComponent {
  @Input() title: string = '';
  @Input() description: string = '';
}